import {v4 as uuidv4} from 'uuid';

ko.components.register('form-checkbox', {
	viewModel: function (params) {
		var self = this;
		self.type = "FormCheckboxComponentViewModel"
		self.id = params.id || 'checkbox-' + uuidv4();
		self.rootvm = params.rootvm;

		self.ko_observable = ko.observable(params.ko_observable);
		self.name = params.name;
		self.hint = params.hint || '';
		self.popoverToggle = params.popoverToggle || false;
		self.popoverTitle = params.popoverTitle || '';
		self.popoverText = params.popoverText || '';
		self.is_required = params.is_required;
		self.is_disabled = ko.observable(params.is_disabled);
		self.is_valid = ko.observable(params.is_valid || false);
		self.is_invalid = ko.observable(params.is_invalid || false);
		self.event = params.event || undefined;
	},
	template: { fromUrl: 'templates/form-checkbox.html', maxCacheAge: 1234 }
});
