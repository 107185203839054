import { Order } from "../class/Order";
import Plyr from "plyr";

export function GYSDeliveryViewModel(data) {
  var self = this;
  self.type = "GYSDeliveryViewModel";

  self.ko = ko;

  console.log("TYPE is ", self.type);

  self.rootvm = data.rootvm;

  self.song = ko.observable(data.song);
  self.greeting = ko.observable(data.greeting);
  self.delivery_message = ko.observable(data.delivery_message);

  self.web_host = data.web_host;
  self.frontend_web_host = data.frontend_web_host;
  self.is_busy = ko.observable(false);
  self.initialized = ko.observable(false);

  data.order.rootvm = self.rootvm;
  self.order = ko.observable(new Order(data.order));

  self.error = ko.observable();
  self.show_errors = ko.observable(false);
  self.initialize = () => {
    const elementsAudio = document.querySelectorAll("audio.delivery");

    elementsAudio.forEach(function (element) {
      new Plyr(element, {
        controls: [
          "play", // Play/pause playback
          "progress", // The progress bar and scrubber for playback and buffering
          "current-time", // The current time of playback
          "duration", // The full duration of the media
          //'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
        ],
      });
    });

    const player = new Plyr("audio", {});
  };

  self.perspective = data.perspective;
  //self.perspective = 'artist';
  //
  // User feedback
  self.user_feedback = ko.observable();
  self.user_rating = ko.observable(0);

  self.feedback_error = ko.pureComputed(function () {
    return self.user_feedback() === undefined || self.user_feedback === "";
  });

  self.feedback_rating_error = ko.pureComputed(function () {
    return self.user_rating() < 1;
  });

  self.check_feedback_has_error = () => {
      // at the moment, we need at least a rating, but we dont require
      // text
    return self.feedback_rating_error();
  };

  self.rating_star_css = function (rating, star_index) {
    if (rating) {
      if (rating - star_index > 0 && rating - star_index < 1) {
        return "bi-star-fill text-warning";
      } else if (rating - star_index <= 0) {
        return "bi-star";
      } else {
        return "bi-star-fill text-warning";
      }
    } else {
      return "bi-star";
    }
  };

  // Order status

  self.delivered = ko.pureComputed(() => {
    return self.order().status() == "delivered";
  });

  self.deliver_song = function () {
    console.log("Song delivered!");

    return $.ajax({
      url: "/api/order/deliver",
      type: "POST",
      data: ko.toJSON({
        song_uuid: self.song().song_uuid(),
        greeting_song_uuid: self.greeting().song_uuid(),
        order_uuid: self.order().gys_order_uuid(),
      }),
      contentType: "application/json; charset=utf-8",
      processData: false,

      success: function (data) {
        self.order().status("delivered");
      },

      error: function (data) {
        self.error(data.statusText);
      },
    });
  };

  self.submit_song_feedback = () => {
    if (!self.check_feedback_has_error()) {
      self.show_errors(false);
      return $.ajax({
        url: "/api/order/feedback",
        type: "POST",
        data: ko.toJSON({
          order_uuid: self.order().gys_order_uuid(),
          user_feedback: self.user_feedback(),
          user_rating: self.user_rating(),
        }),
        contentType: "application/json; charset=utf-8",
        processData: false,

        success: function (data) {
          self.order().user_feedback(self.user_feedback());
          self.order().user_rating(self.user_rating());
          self.user_feedback(undefined);
        },

        error: function (data) {
          self.error(data.statusText);
        },
      });
    } else {
      self.show_errors(true);
      return false;
    }
  };
}
