import { setCookie, getCookie, eraseCookie } from '../common/junkdrawer';

function CookieConsentComponentViewModel(params) {
    var self = this;
    self.type = "CookieConsentComponentViewModel"


    self.is_busy = ko.observable(false);

    self.analytics_checked = ko.observable(false);
    self.partner_checked = ko.observable(false);
    self.rootvm = params.rootvm || window.scvm;

    self.lookup_cookie_status = function () {
        return getCookie('SC_cookie_consent');
    };

    self.allow = function () {
        setCookie('SC_cookie_consent', 'allow');
        self.SC_cookies_enable();
        self.rootvm.maybe_scroll_to_video();
        return
    }

    self.revoke = function () {

        if (self.analytics_checked() && self.partner_checked()) {
            return self.allow();
        }
        else {
            setCookie('SC_cookie_consent', 'revoke');
            self.SC_cookies_disable();
            self.rootvm.maybe_scroll_to_video();
            return
        }
    }

    self.cookie_title = ko.observable("Cookie-Einstellungen");
    self.cookie_msg = ko.observable("Songgeflüster verwendet Cookies und andere Daten, damit du unsere Dienste bestmöglich nutzen kannst und wir uns immer weiter verbessern können. Um deine persönlichen Einstellungen anzupassen, ");
    self.cookie_link = ko.observable("folge dem Link zur Verwaltung.");
    self.cookie_accept = ko.observable("Alle Akzeptieren");
    self.cookie_save = ko.observable("Speichern");
    self.cookie_manage = ko.observable("Diese Cookies sind notwendig, um dich vor einem möglichen Datenverlsut beim Bestellprozess zu schützen. Außerdem verwenden wir ausgewählte Analyse-Cookies, die uns helfen, unsere Plattform ständig zu verbessern. Hier kannst du deine persönlichen Einstellungen anpassen:");
    self.cookie_catgories = ko.observableArray([
        {
            variable: ko.observable(true),
            label: "Notwendige Cookies",
            description: "Wenn du eine Bestellung initiiert wird ein Cookie gesetzt, damit deine eingetragenen Daten nicht verloren gehen, für den Fall, dass du den Bestellprozess versehentlich abbrichst oder die Webseite unabsichtlich neugeladen wird. Für Künstler*innen: Wenn du dich bei Songgeflüster anmeldest, wird ein Cookie gesetzt und du bleibst eingeloggt, auch wenn Du zwischenzeitlich die Seite verlässt."
        },
        {
            variable: self.analytics_checked,
            label: "Analyse Cookies",
            description: "Diese Cookies helfen uns, unsere Seite zu analysieren und zu verstehen, wer welche Seiten besucht - natürlich anonym. Das ist wichtig, damit wir besser verstehen, welche Seiten wirklich hilfreich sind und welche wir verbessern sollten, damit du am Ende zufrieden bist und schnell an deinen personalisierten Song kommst!"
        },
        {
            variable: self.partner_checked,
            label: "Partner Cookies",
            description: "Diese Cookies sind Cookies der Partner, mit denen wir zusammenarbeiten um zum einen die Sicherheit und den Schutz vor Betrug und Missbrauch im Internet unsere eigene Webseite und Angebote zu verbessern und um sinnvollere und passendere Marketing Angebote auszuspielen."
        },
    ]);


    self.initialize = function () {

        /*self.hotjar_init = function () {
		(function(h,o,t,j,a,r){
			h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
			h._hjSettings={hjid:2957968,hjsv:6};
			a=o.getElementsByTagName('head')[0];
			r=o.createElement('script');r.async=1;
			r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
			a.appendChild(r);
		})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        }*/


        var c_status = self.lookup_cookie_status();
        if (c_status === null) {
            /*setTimeout(function(){
                // We don't want this immediately, as it will impact
                // CLS Scores
                $('#modalCookieConsent').modal('show');
                self.log_cookie_shown();
                self.tawkto_init();
            }, 5000);*/


            const listen = document.addEventListener('scroll', function scrollHandler(e) {
                document.removeEventListener('scroll', scrollHandler);
                const cookieModal = new bootstrap.Modal(document.getElementById('modalCookieConsent'), {})
                cookieModal.show();
            });

        }
        else if (c_status === "allow") {
            self.SC_cookies_enable();
        }
    }

    /*
    window.addEventListener('DOMContentLoaded', function(w_event){
        console.log('dom loaded cookie consent');
        var c_status = self.lookup_cookie_status();
    });*/

    self.show_manage = ko.observable(false);

    self.google_init = function () {
        /*
        (function (i, s, o, g, r, a, m) {
          i['GoogleAnalyticsObject'] = r;
          i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments)
          }, i[r].l = 1 * new
          Date();
          a = s.createElement(o),
            m = s.getElementsByTagName(o)[0];
          a.async = 1;
          a.src = g;
          m.parentNode.insertBefore(a, m)
        })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');*/
    };

    self.gtag_init = function () {
        (function (i, s, o, g, r, a, m) {
          i['GoogleAnalyticsObject'] = r;
          i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments)
          }, i[r].l = 1 * new
          Date();
          a = s.createElement(o),
            m = s.getElementsByTagName(o)[0];
          a.async = 1;
          a.src = g;
          m.parentNode.insertBefore(a, m)
        })(window, document, 'script', 'https://www.googletagmanager.com/gtag/js?id=G-S6NDNVXFKF', 'gtag');
    };

    self.fb_init = function () {
        ! function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
            'https://connect.facebook.net/en_US/fbevents.js');

        window.fbAsyncInit = function () {
            FB.init({
                appId: window.facebook_app_id,
                xfbml: true,
                status: true,
                cookie: true,
                version: 'v2.10'
            });
            FB.AppEvents.logPageView();
        };
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

    }

    self.tiktok_init = () =>{
            !function (w, d, t) {
                  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

                    ttq.load('COKHGKRC77UAA32C65EG');
                    ttq.page();
            }(window, document, 'ttq');
    }
    self.pinterest_init = () =>{
        !function(e){if(!window.pintrk){window.pintrk=function(){
             window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var n=window.pintrk;n.queue=[],n.version="3.0";
             var t=document.createElement("script");t.async=!0,t.src=e;
             var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
                pintrk('load', '2612487463641');
                pintrk('page');
                pintrk('track', 'pagevisit')
    }


    self.hotjar_init = function () {
    /*
        (function (h, o, t, j, a, r) {
            h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
            h._hjSettings = { hjid: 2533085, hjsv: 6 };
            a = o.getElementsByTagName('head')[0];
            r = o.createElement('script'); r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    */
    }


    self.SC_cookies_enable = function () {


        if (!self.rootvm.is_prod) {
            console.log("not actually enabling COOKIES, not prod")
            return false;
        }

        // we should only do this if we're in production mode

        //self.google_init();
        //self.gtag_init();
        self.fb_init();
        self.tiktok_init();
        self.pinterest_init();
        //self.hotjar_init();

        /*
        !function (e) { if (!window.pintrk) { window.pintrk = function () { window.pintrk.queue.push(Array.prototype.slice.call(arguments)) }; var n = window.pintrk; n.queue = [], n.version = "3.0"; var t = document.createElement("script"); t.async = !0, t.src = e; var r = document.getElementsByTagName("script")[0]; r.parentNode.insertBefore(t, r) } }("https://s.pinimg.com/ct/core.js");
        // TODO: mv id to yaml config
        pintrk('load', '2614314633066');
        pintrk('page');
        */
        if (typeof fbq !== "undefined") {
            // TODO: mv id to yaml config
            fbq('init', window.facebook_app_id);
            fbq('track', 'PageView');
            fbq('consent', 'grant');
        }


        if (typeof gtag !== "undefined") {
            gtag('consent', 'default', {
                'ad_storage': 'granted',
                'analytics_storage': 'granted'
            });
            gtag('config', 'G-S6NDNVXFKF')
        }
		// Turn on HotJar too
		/*(function(h,o,t,j,a,r){
			h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
			h._hjSettings={hjid:3829292,hjsv:6};
			a=o.getElementsByTagName('head')[0];
			r=o.createElement('script');r.async=1;
			r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
			a.appendChild(r);
		})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');*/

    }

    self.SC_cookies_disable = function () {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            if (name.indexOf('session_hexdigest') < 0 && name.indexOf('session_uuid') < 0) {
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }

        }

        return
    }
}

ko.components.register('cookie-consent', {
    viewModel: {
        createViewModel: function (params, componentInfo) {
            var vm = new CookieConsentComponentViewModel(params);
            vm.initialize();
            return vm;
        },
    },
    template: `
       <div class="modal fade" tabindex="-1" role="dialog" id="modalCookieConsent"
            data-bs-keyboard="false" data-bs-backdrop="static">
          <div class="modal-dialog modal-dialog-scrollable" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 data-bind="text: cookie_title"></h5>
              </div>
              <div class="modal-body py-0">
                <div data-bind="visible:!show_manage()">
                    <span data-bind="text: cookie_msg"></span>
                    <a href="" data-bind="text: cookie_link, click: () => show_manage(true)"></a>
                </div>
                <div data-bind="visible:show_manage">
                    <p data-bind="text: cookie_manage"></p>
                    <div class="d-flex flex-column gap-1 small" data-bind="foreach: cookie_catgories">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox"
                                data-bind="attr: {id: $index}, checked: variable, disable: $index() === 0" />
                            <label class="form-check-label" data-bind="attr: {for: $index}">
                                <span class="fw-bold" data-bind="text: label"></span>
                                <span data-bind="text: description"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal" data-bind="
                text: cookie_save,
                click:revoke,
                visible:show_manage
              ">Save</button>
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal" data-bind="
                click:allow,
                text: cookie_accept
              ">Allow</button>
            </div>
          </div>
       </div>
    `
});
