import * as Pikaday from 'pikaday';
import moment from 'moment';

export const templateFromUrlLoader = {
    loadComponent: function(name, componentConfig, callback){
          ko.components.defaultLoader.loadComponent(name, componentConfig, callback);
    },

    loadTemplate: function(name, templateConfig, callback) {
        if (templateConfig.fromUrl) {
            // Uses jQuery's ajax facility to load the markup from a file
            if(typeof(scvm) !== "undefined"){
                var fullUrl = '/dist/assets/js/js-modules/components/' + templateConfig.fromUrl + '?cacheAge=' + scvm.frontend_web_version();
            }
            else{
                var fullUrl = '/dist/assets/js/js-modules/components/' + templateConfig.fromUrl + '?cacheAge=';
            }

            $.ajax({
                url: fullUrl,
                type: 'GET',
                cache: true,

                contentType: "text/html; charset=utf-8",
                success: function (markupString) {

                  if(templateConfig.needsGoogle && typeof google === 'undefined'){
                    // need our own API Key here
                    const REACT_APP_API_KEY = 'AIzaSyDqe0t7azCCnjagTCKQppLKfGpB01uNX34';
                    const googleMapScript = document.createElement('script');
                    googleMapScript.src=`https://maps.googleapis.com/maps/api/js?key=${REACT_APP_API_KEY}&libraries=places`;
                    googleMapScript.async = true;
                    window.document.body.appendChild(googleMapScript);
                    googleMapScript.addEventListener('load', () => {
                        ko.components.defaultLoader.loadTemplate(name, markupString, callback);
                    });

                  }
                  else{
                    ko.components.defaultLoader.loadTemplate(name, markupString, callback);
                  }
                }

            });
            /*
            $.get(fullUrl, function(markupString) {
                // We need an array of DOM nodes, not a string.
                // We can use the default loader to convert to the
                // required format.
                ko.components.defaultLoader.loadTemplate(name, markupString, callback);
            });
            */
        } else {
            // Unrecognized config format. Let another loader handle it.
            callback(null);
        }
    }
};

export function koBindingsSetup (){
    // set up truncatedText
    ko.bindingHandlers.truncatedText = {
        update: function (element, valueAccessor, allBindingsAccessor) {
            var originalText = ko.utils.unwrapObservable(valueAccessor());
            // 10 is a default maximum length
            var truncatedText;
            if (originalText){
            length = ko.utils.unwrapObservable(allBindingsAccessor().maxTextLength) || 20;
            truncatedText = originalText.length > length ? originalText.substring(0, length) + "..." : originalText;
            }
            else{
                truncatedText = '';
            }
            // updating text binding handler to show truncatedText
            ko.bindingHandlers.html.update(element, function () {
                return truncatedText;
            });
        }
    };
    // Found on the jobs page
    ko.bindingHandlers.maxTextLengthToggle = {
        init: function (element, valueAccessor, allBindingsAccessor) {
            var maxTextLength = ko.utils.unwrapObservable(valueAccessor());

            // All of the spans
            var element = element;
            var originalTexts = ko.observableArray([])
            $(element).find('span').each(function(i, ele){
                originalTexts.push($(this).html());
            });


            // hook up click handler on each button
            var buttons = $(element).next().find('button');

            var moreButton = buttons[0];
            var lessButton = buttons[1];

            var truncated = ko.observable(true);
            var opposite = ko.computed(function(){
                return !truncated();
            });
            var clickHandler = function(){
                truncated(!truncated());
                setText();
            };

            //apply bindings to anchor
            ko.applyBindingsToNode(moreButton, { click: clickHandler, visible: truncated });
            ko.applyBindingsToNode(lessButton, { click: clickHandler, visible: opposite });

            var setText = function(){
                var truncatedText;
                $(element).find('span').each(function(i, ele){

                    if(truncated()){
                        if (originalTexts()[i]){
                            length = maxTextLength || 20;
                            truncatedText = originalTexts()[i].length > length ? originalTexts()[i].substring(0, length) + "..." : originalTexts()[i];
                        }
                        else{
                            truncatedText = '';
                        }

                        $(ele).html(truncatedText);
                    }
                    else{
                        $(ele).html(originalTexts()[i]);
                    }
                });
            }

            setText();
        }
    };
    ko.bindingHandlers.maxTextLengthToggleBlog = {
        init: function (element, valueAccessor, allBindingsAccessor) {
            var maxTextLength = ko.utils.unwrapObservable(valueAccessor());

            // All of the spans
            var element = element;
            var originalTexts = ko.observableArray([])
            $(element).find('p').each(function(i, ele){
                originalTexts.push($(this).html());
            });


            // hook up click handler on each button
            var buttons = $(element).next().find('button');

            var moreButton = buttons[0];
            var lessButton = buttons[1];

            var truncated = ko.observable(true);
            var opposite = ko.computed(function(){
                return !truncated();
            });
            var clickHandler = function(){
                truncated(!truncated());
                setText();
            };

            //apply bindings to anchor
            ko.applyBindingsToNode(moreButton, { click: clickHandler, visible: truncated });
            ko.applyBindingsToNode(lessButton, { click: clickHandler, visible: opposite });

            var setText = function(){
                var truncatedText;

                // set the parent tile class
                truncated() ? $(element).closest('.tile').removeClass('show-more') : $(element).closest('.tile').addClass('show-more');

                $(element).find('p').each(function(i, ele){

                    if(truncated()){
                        if (originalTexts()[i]){
                            length = maxTextLength || 20;
                            truncatedText = originalTexts()[i].length > length ? originalTexts()[i].substring(0, length) + "..." : originalTexts()[i];
                        }
                        else{
                            truncatedText = '';
                        }

                        $(ele).html(truncatedText);
                    }
                    else{
                        $(ele).html(originalTexts()[i]);

                    }
                });
            }

            setText();
        }
    };


    ko.bindingHandlers.popover = {
      init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        ko.bindingHandlers.value.init(element, valueAccessor, allBindings);
        var source = allBindings.get('popoverTitle');
        var placement = allBindings.get('popoverPlacement');
        if(!placement){
            placement = 'auto';
        }
        var container = $(element).attr('data-container') || 'body';
        var sourceUnwrapped = ko.unwrap(source);

        $(element).popover({
          content: valueAccessor(),
          title: sourceUnwrapped,
          container: container,
          placement: placement
        });
      },
      update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        var source = allBindings.get('popoverTitle');
        var container = $(element).attr('data-container') || 'body';
        var sourceUnwrapped = ko.unwrap(source);
        var placement = allBindings.get('popoverPlacement');
        ko.bindingHandlers.value.update(element, valueAccessor);

        $(element).popover({
          content: valueAccessor(),
          title: sourceUnwrapped,
          container: container
        });

      }
}

    ko.bindingHandlers.afterRenderComponentInput = {
        update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
            // val should be a call back function
            var val = valueAccessor();
            // execute
            val(element);
        }
    };

    // From MBest on the knockout issue list
    ko.subscribable.fn.subscribeChanged = function (callback) {
        var savedValue = this.peek();
        return this.subscribe(function (latestValue) {
            var oldValue = savedValue;
            savedValue = latestValue;
            callback(latestValue, oldValue);
        });
    };

    ko.bindingHandlers.typeahead = {
        init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
            var $element = $(element);
            var allBindings = allBindingsAccessor();
            var typeaheadArr = ko.utils.unwrapObservable(valueAccessor());

            $element.attr("autocomplete", "off")
                    .typeahead({
                        'source': typeaheadArr,
                        'minLength': allBindings.minLength,
                        'items': allBindings.items,
                        'updater': allBindings.updater
                    });
        }
    };


    ko.bindingHandlers.slideVisible = {
        init: function (element, valueAccessor) {
            var value = ko.utils.unwrapObservable(valueAccessor());
            $(element).toggle(value);
        },
        update: function (element, valueAccessor) {
            var value = ko.utils.unwrapObservable(valueAccessor());
            value ? $(element).slideDown() : $(element).slideUp();
        }
    };


    /* Good to have a toggle function available to true/false observables */
    ko.observable.fn.toggle = function () {
        var obs = this;
        return function () {
            obs(!obs())
        };
    };
    // increment / decrement
    ko.observable.fn.increment = function (value) {
        value !== 0 ? this(this() + (value || 1)) : this;
    };
    ko.observable.fn.decrement = function (value) {
        value !== 0 ? this(this() - (value || 1)) : this;
    };


    ko.bindingHandlers['dynhtml'] = {
        'init': function() {
            return { 'controlsDescendantBindings': true };
        },
        'update': function (element, valueAccessor,
            allBindings, viewModel, bindingContext) {
            ko.utils.setHtml(element, valueAccessor());
            ko.applyBindingsToDescendants(bindingContext, element);
        }
    };



    // Pikaday
    //
    var german_pikaday = {
        previousMonth : 'Vormonat',
        nextMonth     : 'Nächster Monat',
        months        : ['Januar','Februar', 'März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
        weekdays      : ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'],
        weekdaysShort : ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.']
    };

    ko.bindingHandlers.pikadaymoment = {
        init: function(element, valueAccessor, allBindings,
                viewModel, bindingContext) {


		  var lang = german_pikaday;

          // keep a reference to the pikaday
          if(element.pikstore == undefined){
              element.pikstore = [];
          }

          var format = allBindings().pikaday_format || 'dd, ll';
          var value = ko.unwrap(valueAccessor());
          var maxDateToday = allBindings().pikaday_maxDateToday || false;
          var minDateToday = allBindings().pikaday_minDateToday || false;
          var minDate = allBindings().pikaday_minDate || false;
          var maxDate = allBindings().pikaday_maxDate || false;


          var options = { format: format,
				firstDay: 1,
				position: 'top left',
				field: element,
                yearRange: 100,
                keyboardInput: false,
				onSelect: allBindings().pikaday_adddate,
                onClose: function(){
                    if($(element).val() == ''){
                        this.setDate(null);
                        allBindings().pikaday_adddate(undefined)
                    }
                }};

		  if(lang){
			  options['i18n'] = lang;
		  }


          if (allBindings().pikaday_container){
              options['bound'] = false;
              options['container'] = document.getElementById(allBindings().pikaday_container);
          }

          if (maxDateToday){
              options['maxDate'] = new Date()
          }
          if (minDateToday){
              options['minDate'] = new Date()
          }

          if (minDate){
              options['minDate'] = new moment(minDate).toDate();
              options['defaultDate'] = options['minDate'];
              options['setDefaultDate'] = true;
          }
          if (maxDate){
              options['maxDate'] = new moment(maxDate).toDate();
              options['defaultDate'] = options['minDate'];
          }

		  var pikaday = new Pikaday(options);

          if (value){
              pikaday.setMoment(moment(value));
          }
          // Store all the pikadays associated with this value
          element.pikstore.push(pikaday);
      },
      update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        var value = ko.unwrap(valueAccessor());
        //ko.bindingHandlers.value.update(element, valueAccessor);
        if(value != undefined){
            if(element.pikstore){
                for (var i = 0; i < element.pikstore.length; i++){
                     var p = element.pikstore[i];
                     if(p.getMoment() && p.getMoment().format('MM-DD-YYYY') != value){
                         element.pikstore[i].setMoment(moment(value), false);
                     }
                }
            }
        }
      }
    };


  const setupkoMedia = function()
  {
    var
        bindingName = 'ifQuery',
        cloneNodes = function ( nodesArray, shouldCleanNodes )  /* copied directly from ko.utils, since it doesn't expose it publicly */
        {
            for ( var i = 0, j = nodesArray.length, newNodesArray = []; i < j; i++ )
            {
                var clonedNode = nodesArray[i].cloneNode( true );
                newNodesArray.push( shouldCleanNodes ? ko.cleanNode( clonedNode ) : clonedNode );
            }

            return newNodesArray;
        },
        domDataKey = '__ko_ifQueryBindingData',
        // use bootstraps 4 default breakpoints
        queries =
        {
            "xs":       "(max-width: 575.98px)",
            "sm-down":  "(max-width: 767.98px)",
            "sm":       "(min-width: 576px) and (max-width: 767.98px)",
            "sm-up":    "(min-width: 576px)",
            "md-down":  "(max-width: 991.98px)",
            "md":       "(min-width: 768px) and (max-width: 991.98px)",
            "md-up":    "(min-width: 768px)",
            "lg-down":  "(max-width: 1199.98px)",
            "lg":       "(min-width: 992px) and (max-width: 1199.98px)",
            "lg-up":    "(min-width: 992px)",
            "xl":       "(min-width: 1200px)"
        }
        /* queries =
        {
            "small": "(max-width: 767px)",
            "medium-down": "(max-width: 1279px)",
            "medium": "(min-width: 768px) and (max-width: 1279px)",
            "medium-up": "(min-width: 768px)",
            "large-down": "(max-width: 1439px)",
            "large": "(min-width: 1280px) and (max-width: 1439px)",
            "large-up": "(min-width: 1280px)",
            "xlarge": "(min-width: 1440px)",
            "landscape": "(orientation: landscape)",
            "portrait": "(orientation: portrait)",
        }; */ /* Size definitions based on http://foundation.zurb.com/docs/components/visibility.html */

    ko.bindingHandlers[bindingName] =
    {
        'init': function ( element, valueAccessor, allBindingsAccessor, viewModel, bindingContext )
        {
            ko.utils.domData.set( element, domDataKey, {} );

            return { 'controlsDescendantBindings': true };
        },
        'update': function ( element, valueAccessor, allBindingsAccessor, viewModel, bindingContext )
        {
            var ifQueryData = ko.utils.domData.get( element, domDataKey ),
                dataValue = ko.utils.unwrapObservable( valueAccessor() ),
                isFirstRender = !ifQueryData.savedNodes,
                split = dataValue.split( "," ),
                finalQuery = "";

            for ( var i = 0; i < split.length; i++ )
            {
                var query = queries[split[i]] || split[i];

                finalQuery += ( finalQuery ? " and " + query : query );
            }

            finalQuery = finalQuery.replace( "only screen and", "" );

            var
                shouldDisplay = matchMedia( finalQuery ).matches,
                needsRefresh = isFirstRender || ( shouldDisplay !== ifQueryData.didDisplayOnLastUpdate );

            if ( needsRefresh )
            {
                if ( isFirstRender )
                {
                    ifQueryData.savedNodes = cloneNodes( ko.virtualElements.childNodes( element ), true /* shouldCleanNodes */ );
                }

                if ( shouldDisplay )
                {
                    if ( !isFirstRender )
                    {
                        ko.virtualElements.setDomNodeChildren( element, cloneNodes( ifQueryData.savedNodes ) );
                    }

                    ko.applyBindingsToDescendants( bindingContext, element );
                }
                else
                {
                    ko.virtualElements.emptyNode( element );
                }

                ifQueryData.didDisplayOnLastUpdate = shouldDisplay;
            }
        }
    };

    ko.expressionRewriting.bindingRewriteValidators[bindingName] = false; // Can't rewrite control flow bindings
    ko.virtualElements.allowedBindings[bindingName] = true;
  };

  setupkoMedia();
}


export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};



// Client side cookies
export function setCookie(name, value, days) {
    var expires = "";

    if(days === undefined){
        days = 30;
    }
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
export function eraseCookie(name) {
    document.cookie = name+'=; Max-Age=-99999999;';
}

//Client Side Local Storage
export function setLocalStorage(name, value){
    if (typeof(Storage) !== "undefined") {
            window.localStorage.setItem(name, JSON.stringify(value));
        } else {
            console.log("No storage available");
        }
}

export function getLocalStorage(name){
    if (typeof(Storage) !== "undefined") {
        return JSON.parse(window.localStorage.getItem(name));
    } else {
        console.log("No storage available");
    }
}


export function global_currency_exchange (starting_amount, currency,
    preferred_currency, exchange_rates){
        if(exchange_rates == undefined){
            return
        }
        if( currency != 'EUR' && preferred_currency != 'EUR'){
            starting_amount = global_currency_exchange(starting_amount, currency, 'EUR', exchange_rates)
            currency = 'EUR'
        }

        if (currency == 'EUR'){
            var exchange_rate = exchange_rates[preferred_currency];
            var new_amount = exchange_rate * starting_amount
        }

        else if(preferred_currency == 'EUR'){
            var exchange_rate = exchange_rates[currency];

            var new_amount =  starting_amount / exchange_rate;
        }

        return Math.round(new_amount / 10) * 10;

};


// Google hit
export function sendGoogleEvent(eventCategory, eventAction, eventLabel, eventValue){
    // ga is global google analytics
    if (typeof gtag != 'undefined') {
        gtag('event', eventAction, {
            'event_category': eventCategory,
            'event_action': eventAction,
            'value': eventValue
        });
    }
}

export function extractFromAdress(components, type, shortName){
    for (var i=0; i<components.length; i++)
        for (var j=0; j<components[i].types.length; j++)
            if (components[i].types[j] == type) {
                return shortName ? components[i].short_name : components[i].long_name;
            }
    return "";
}

// SofaConcerts pageview
export function logPageView(web_host, page, full_href, referrer){
    // SC User cookie is automatically sent
    let gysUser = getCookie('gysUser');
    if (gysUser === null)
    {
       gysUser = Math.round(Math.random() * 1000000000);
       setCookie('gysUser', gysUser ,180);
    }

    return $.ajax({
        url: web_host + '/api/log/page-view',
        type: "POST",
        contentType: "application/json; charset=utf-8",
        processData: false,
        data: ko.toJSON({
            page: page,
            referrer: referrer,
            full_href: full_href,
            gysUser: gysUser
        }),

        complete: function (data) {},

        success: function (data) {
        },

        error: function (data) {
            console.log(data);
        }
    });
}



export function logEvent (web_host, frontend_event_type, page,
	message) {

    let gysUser = getCookie('gysUser');
    if (gysUser === null)
    {
       gysUser = Math.round(Math.random() * 1000000000);
       setCookie('gysUser', gysUser ,180);
    }

	return $.ajax({
		url: web_host + '/api/log/frontend-event',
		type: "POST",
		contentType: "application/json; charset=utf-8",
		processData: false,
		data: ko.toJSON({
			frontend_event_type: frontend_event_type,
			page: page,
			message: message,
            gysUser: gysUser
		}),

		complete: function (data) {},

		success: function (data) {
		},

		error: function (data) {
			console.log(data);
		}
	});
}

