import {logEvent} from '../common/junkdrawer';

export function Artist(data){
    var self = this;
    self.type = "GYSArtist";

    self.username = data.username;
    self.artist_name = data.artist_name;

    self.artist_uuid = data.artist_uuid;

    self.preview_photo = data.preview_path;
    self.profile_photo = data.profile_path;

    self.slogan = data.short_slogan;
    self.description = data.description;

    self.languages = data.languages;
    self.price = data.price;
    self.pretty_price = Number(data.price).toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
    });

    self.mp3_sample_de = data.mp3_sample_de;
    self.mp3_sample_en = data.mp3_sample_en;
    self.mp3_player = document.getElementById('audio-player');
    self.mp3_progress = ko.observable(0);
    self.mp3_is_playing = ko.observable(false);

    self.is_selected = ko.observable(false);

    // Artist Play Button
    self.artist_play_click = () => {
        if(self.mp3_is_playing()) {
            self.mp3_player.pause();
            self.mp3_is_playing(false);
        }
        else {
            if (self.mp3_player.src.indexOf(self.mp3_sample_de) === -1) {
                self.mp3_player.src = self.mp3_sample_de;
            }
            self.mp3_player.play();
            self.mp3_is_playing(true);

            logEvent(window.sc_web_host, 'sample_play',
                window.location.pathname, self.username);

            if(typeof fbq != 'undefined'){

                fbq('track', 'ViewContent', {
                      'content_name':  self.username,
                       'content_ids': [self.artist_uuid],
                       'content_type': "Artist"
                });
            }

            if(typeof ttq != 'undefined'){
				ttq.track('ViewContent', {
					contents:[{
					'content_name': self.username,
					'content_id': self.artist_uuid,
					'content_category': "Artist"
					}],
					"value": self.price,
					"currency" : "EUR"
				});
            }

            if(typeof ttq != 'undefined'){
				ttq.track('ViewContent', {
					contents:[{
					'content_name': self.username,
					'content_id': self.artist_uuid,
					'content_category': "Artist"
					}],
					"value": self.price ,
					"currency" : "EUR"
				});
            }
            if( typeof pintrk != 'undefined'){
                pintrk('track', 'watchvideo', {
                    currency: 'EUR',
                    line_items: [{
					'product_name': self.username,
					'product_id': self.artist_uuid,
					'product_category': "Artist",
                    'product_price': self.price ,
                    }]
                });
            }

        }


        return false;
    };

    self.mp3_player.addEventListener('loadeddata', () => {
        if (self.mp3_player.src.indexOf(self.mp3_sample_de) === -1) {
            self.mp3_is_playing(false);
            self.mp3_progress(0);
        }
    })
    self.mp3_player.addEventListener('ended', () => {
        if (self.mp3_player.src.indexOf(self.mp3_sample_de) !== -1) {
            self.mp3_is_playing(false);
        }
    })
    self.mp3_player.addEventListener('timeupdate', (a) => {
        if (self.mp3_player.src.indexOf(self.mp3_sample_de) !== -1) {
            self.mp3_progress(self.mp3_player.currentTime/self.mp3_player.duration*100);
        }
    })
}

export async function lookup_artists() {
    const response = await fetch(API_ROOT + '/artists', { headers: { 'Accept': 'application/json' }});
    const data = await response.json();
    if (data.success) {
        return data.artists
    } else {
        throw new Error(data.message)
    }
};
