import {v4 as uuidv4} from 'uuid';
ko.components.register('form-multiselect', {
	viewModel: function (params) {
		var self = this;
		self.type = "FormMultiselectComponentViewModel"
		self.id = params.id || 'multiselect-' + uuidv4();
		self.rootvm = params.rootvm;

		self.ko_observableArray = params.ko_observableArray || ko.observableArray({});
		self.options = params.options ||  ko.observableArray([{value: 'here', label: 'wähle 3 Lieder aus'}]);
		self.optionsText = params.optionsText || 'label',
		self.optionsValue = params.optionsValue || undefined,
		self.option_clicked = params.option_clicked || function(item) {console.log(item);}
        self.container_clicked = params.container_clicked || function() {console.log('click');}
		self.show_option_examples = params.show_option_examples || false;
		self.name = params.name;
		self.hint = params.hint || '';
		self.popoverToggle = params.popoverToggle || false;
		self.popoverTitle = params.popoverTitle || '';
		self.popoverText = params.popoverText || '';
		self.is_required = params.is_required;
		self.is_disabled = ko.observable(params.is_disabled);
		self.is_valid = ko.observable(params.is_valid || false);
		self.valid_feedback = ko.observable(params.valid_feedback || '');
		self.is_invalid = ko.observable(params.is_invalid || false);
		self.invalid_feedback = ko.observable(params.invalid_feedback || '');

        self.no_margin = ko.observable(params.no_margin);
        self.include_remove_icon = ko.observable(params.include_remove_icon);

        self.include_remove_icon_text = function(optionsText){
            // this assumes no i18n and that options text is not a
            // function -- We can integrate those other two options at a
            // later date
            return optionsText[self.optionsText] + ' <i class="mdi mdi-close"></i>';
        }

	},
	template: { fromUrl: 'templates/form-multiselect.html', maxCacheAge: 1234 }
});
