import {v4 as uuidv4} from 'uuid';

ko.components.register('form-select', {
	viewModel: function (params) {
		var self = this;
		self.type = "FormSelectComponentViewModel"
		self.id = params.id || 'select-' + uuidv4();
		self.rootvm = params.rootvm;
		self.container_class = params.container_class !== undefined ? params.container_class : 'form-group';

		self.ko_observable = params.ko_observable;
		self.options = params.options ||  ko.observableArray([{value: 'dummy', i18n: 'dummy'}]);
		self.optionsCaption = params.optionsCaption || undefined,
		self.optionsText = params.optionsText || function(item) {return self.getOptionsText(item)},
		self.optionsValue = params.optionsValue || 'value',
		self.name = params.name;
		self.hint = params.hint || '';

        if(typeof params.hint_placement == "function"){
            self.hint_placement = params.hint_placement();
        }
        else {
            // either top or bottom
            self.hint_placement = params.hint_placement || 'top';
        }

		self.popoverToggle = params.popoverToggle || false;
		self.popoverTitle = params.popoverTitle || '';
		self.popoverText = params.popoverText || '';
		self.is_required = params.is_required;
		self.is_disabled = ko.observable(params.is_disabled);
		self.is_valid = ko.observable(params.is_valid || false);
		self.is_invalid = ko.observable(params.is_invalid || false);

        self.no_border = ko.observable(params.no_border === true);
        self.full_height = false;
        if (params.full_height === true){
            self.full_height = true;
        }

		self.getOptionsText = function(item) {
			return typeof(item.i18n) === 'object' ?
				i18nextko.i18n.translator.translate(item.i18n.key, item.i18n.options) :
				i18nextko.i18n.translator.translate(item.i18n);
		}
	},
	template: `
    <div class="form-group" data-bind="
        css: {'has-error': is_invalid()},
        style: {'width': full_height ? '100%': '', 'height': full_height ? '100%' : '', 'margin-bottom': no_border() ? '0.5rem' : ''},
        class: container_class
    ">
        <!--ko if: name-->
            <label class="sc-form-label" data-bind="attr: {for: id}">
                <span data-bind="i18n: name"></span>
                <span data-bind="if: is_required">*</span>
            </label>
        <!-- /ko -->
        <!--ko if: popoverToggle -->
            <a class="mdi mdi-help-circle" data-toggle="popover" tabindex="0" data-html="true" data-trigger="focus"
            data-bind="
                popover:function() { return i18nextko.i18n.translator.translate(popoverText)},
                popoverTitle:function() { return i18nextko.i18n.translator.translate(popoverTitle)}
            "></a>
        <!-- /ko -->
        <!-- ko if: hint_placement == 'top' && hint -->
        <small class="form-text text-muted sc-form-hint" data-bind="attr: {id: id+'-hint'}, i18n: hint"></small>
        <!-- /ko -->
        <div class="sc-form-select" data-bind="
            css: {
                'h-100': full_height,
                'border-0': no_border(),
                'is-invalid': is_invalid()
            },
        ">
            <select data-bind="
                optionsCaption: optionsCaption,
                options: options,
                optionsText: optionsText,
                optionsValue: optionsValue,
                valueAllowUnset: !is_required,
                value: ko_observable,
                disable: is_disabled(),
                css: {
                    'is-valid': is_valid(),
                    'is-invalid': is_invalid(),
                },
                style:{
                    'color' : ko_observable() === undefined ? '#6c757d' : ''
                },
                attr: {
                    id: id,
                    'aria-describedby': id+'-hint',
                    required: is_required
                }
            "></select>
            <span class="focus"></span>
        </div>
        <!-- ko if: hint_placement == 'bottom' && hint -->
        <small class="form-text text-muted sc-form-hint" data-bind="attr: {id: id+'-hint'}, i18n: hint"></small>
        <!-- /ko -->
    </div>
    `
});
