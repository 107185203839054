import { Order } from "../class/Order";
import { logEvent, validateEmail, setCookie, getCookie } from "../common/junkdrawer";
import { Artist, lookup_artists } from "../class/Artist";
import { BillingDetails, Country } from "../class/Billing";
import { LEGALFORM_TYPES } from "../class/constants";
import { tns } from "tiny-slider";
import { Popover } from "bootstrap";

export function GYSOrderViewModel(data) {
  var self = this;
  self.type = "GYSOrderViewModel";

  self.ko = ko;

  console.log("TYPE is ", self.type);

  self.rootvm = data.rootvm;

  self.web_host = data.web_host;
  self.frontend_web_host = data.frontend_web_host;
  self.is_busy = ko.observable(false);
  self.initialized = ko.observable(false);

  //params
  self.artist = ko.observable();
  self.occasion = ko.observable();
  self.transactionId = ko.observable();
  self.payin_result_code = ko.observable();
  self.payment_success = ko.observable();
  self.order = ko.observable(new Order({ rootvm: self.rootvm }));

  self.email_save_progress = ko.observable();
  self.email_save_progress_confirm = ko.observable();
  self.email_save_progress_submitted = ko.observable(false);

  self.error = ko.observable();
  self.show_errors = ko.observable(false);

  self.show_sorry_alert = ko.observable(false);
  self.show_corporate_alert = ko.observable(false);

  self.saveModal = undefined;

  self.artists = ko.observableArray([]);
  /*
    self.filtered_artists = ko.pureComputed(() => {
        return self.artists().filter(artist => {
            if (self.order().song_language()) {
                return artist.languages.indexOf(self.order().song_language()) >= 0;
            }
            return true
        });
    });
    */
  self.filtered_artists = ko.observableArray([]);
  self.filtered_artists.subscribe((newValue) => {
    if (newValue.length <= 0 || !self.order().selected_artist()) return;
    const index = newValue.findIndex((item) => {
      return item.username == self.order().selected_artist();
    });
    if (index < 0) {
      const langModal = new bootstrap.Modal(
        document.getElementById("modalLangSwitch"),
        {}
      );
      langModal.show();
    }
  });

  self.pretty_artist_name = ko.pureComputed(function () {
    let artist = ko.utils.arrayFirst(self.artists(), (item) => {
      return item.username == self.order().selected_artist();
    });
    if (artist) {
      return artist.artist_name;
    }
  });
  self.pretty_price = ko.pureComputed(() => {
    let artist = ko.utils.arrayFirst(self.artists(), (item) => {
      return item.username == self.order().selected_artist();
    });
    if (artist) {
      return Number(artist.price).toLocaleString("de-DE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
      });
    }
    return "ab 200 €"
  });
  self.min_price = ko.pureComputed(() => {
    const minimum_price = Math.min(...self.filtered_artists().map((a) => a.price));
    return minimum_price.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
    });
  })

  self.selected_artist_obj = ko.observable();

  self.language_changed = function (newValue) {
    self.filtered_artists([]);
    self.filtered_artists(
      ko.utils.arrayFilter(self.artists(), (artist) => {
        if (newValue) {
          return artist.languages.indexOf(newValue) >= 0;
        }
        return true;
      }));
  };

  self.confirm_lang_change = () => {
    self.order().selected_artist(undefined);
    ko.utils.arrayForEach(self.artists(), (item) => {
      item.is_selected(false);
    });
  };

  self.stop_lang_change = () => {
    self.order().song_language(
        self.order().song_language() == "de" ? "en" : "de");
  };

  self.reset_order = () => {
    self.order().reset();
  };

  self.artist_selected = function (new_value) {
    if (new_value == true) {
      let artist = this;

      ko.utils.arrayForEach(self.artists(), (item) => {
        if (item.username !== artist.username) {
          item.is_selected(false);
        }
      });

      self.order().selected_artist(artist.username);
      let index = self.filtered_artists().findIndex((item) => {
        return item.username == self.order().selected_artist();
      });
      self.slider.goTo(index);
    }
    // don't do anything if false
  };

  self.page2_after_show = function () {
    $("body").scrollTop(0, 0);
  };

  self.initialize = async () => {

    const artists = await lookup_artists();

    self.artists(
      artists.map((artist) => {
        const newArtist = new Artist(artist);
        if (newArtist.username == self.order().selected_artist()) {
          newArtist.is_selected(true);
        }

        // hook up subscription
        newArtist.is_selected.subscribe(self.artist_selected, newArtist);

        return newArtist;
      })
    );
    self.filtered_artists(self.artists());


    // Use occasion parameter to set anlass. This will be overwritten if
    // it's already in the cookie in the method below.
    self.order().anlass(self.occasion());

    // Need to wait for artists for pricing
    self.lookup_current_order_progress();


    let genre_popover = new Popover(document.querySelector("#popover-genre"), {
      container: "body",
    });

    // Order Init
    //self.order().song_story.subscribe(()=>{
    //    self.order().save_to_cookie(1);
    //});

    self.delayed_song_story = ko
      .pureComputed(self.order().song_story)
      .extend({ rateLimit: { method: "notifyWhenChangesStop", timeout: 400 } });

    self.delayed_song_story.subscribe(() => {
      self.order().save_to_cookie(1);

      self.show_sorry_alert(self.check_song_order_for_sorry());
      self.show_corporate_alert(self.check_song_order_for_corporate());
    });

    // Check our song story for a certain rex exp
    self.sorry_regex = new RegExp(/(Entschuldigung)|(Trauerfeier)|(Sorry)|(Tod)|(Beerdigung)/mi);
    self.check_song_order_for_sorry = () => {
        return self.sorry_regex.test(self.order().song_story());
    }

    //
    self.corporate_regex =  new RegExp(/(Firma)|(Unternehmen)|(Mailing)|(Rundmail)|(Mitarbeiter)|(Posting)|(Warteschleife)|(Startup)|(Coaching)|(Social Media)|(Newsletter)/mi);
    self.check_song_order_for_corporate = () => {
        return self.corporate_regex.test(self.order().song_story());
    }


    // make sure we're on the right page
    self.maybe_go_back_to_step_one();

    $(window).on('beforeunload', self.maybe_open_save_progress_modal);

    // update our web session
    self.initialized(true);

    self.hotjar_init = function () {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2957968,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    }

  };

  self.maybe_go_back_to_step_one = () => {


    let page_name = pager.getActivePage().currentId;
    if ((page_name !== "step1" && page_name !== "")
            && self.check_song_details_has_errors()) {
      pager.navigate("/order/step1");
    }

    if (
      page_name !== "step2" &&
      page_name !== "step1" &&
      page_name !== "" &&
      self.check_artist_genre_has_errors()
    ) {
      pager.navigate("/order/step2");
    }
  };

  self.lookup_current_order_progress = () => {
    if(window.GYSOrderInProgress !== undefined){
        self.order().load_from_global();
    }
    else{
        self.order().load_from_cookie();
    }

    // then set our slider to the selected artist if we have one
    if (
      self.order().selected_artist() == undefined &&
      self.artist() == undefined
    ) {
      self.order().selected_artist("ALVA");
    } else if (
      self.order().selected_artist() == undefined &&
      self.artist() != undefined
    ) {
      self.order().selected_artist(self.artist());
    }

    if(self.order().gift_card_uuid()){

        self.show_gift_code_section(true);
        self.giftcode_success(true);
        let artist = ko.utils.arrayFirst(self.artists(), (item) => {
          return item.username == self.order().selected_artist();
        });

        self.amount(artist.price - self.order().gift_amount());

    }

    // hook up our language changed handler
    self.order().song_language.subscribe(self.language_changed);
  };

  // Artist Play Button
  self.is_playing = ko.observable(false);
  self.artist_play_click = () => {
    var music = document.getElementById("sample-player");
    if (self.is_playing()) {
      music.pause();
    } else {
      music.play();
    }
  };

  let music = document.getElementById("sample-player");
  if (music) {
    music.addEventListener("pause", function () {
      self.is_playing(false);
    });

    music.addEventListener("play", function () {
      self.is_playing(true);
    });
  }

  // Page 1
  self.who_is_it_for_error = ko.pureComputed(function () {
    return (
      self.order().who_is_it_for_name() == undefined ||
      self.order().who_is_it_for_name() == ""
    );
  });

  self.who_is_it_from_error = ko.pureComputed(function () {
    return (
      self.order().who_is_it_from_name() == undefined ||
      self.order().who_is_it_from_name() == ""
    );
  });

  self.song_story_error = ko.pureComputed(function () {
    return (
      self.order().song_story() == undefined ||
      self.order().song_story() == "" ||
      self.order().song_story().length < 150
    );
  });

  self.song_story_error_text = ko.pureComputed(function() {
      if(self.order().song_story()){
      return `Es fehlen noch ${150 - self.order().song_story().length} Zeichen`;
      }
      else{
        return '';
      }
  });

  self.song_important_error = ko.pureComputed(function () {
    return (
      self.order().song_important() == undefined ||
      self.order().song_important() == ""
    );
  });

  self.anlass_error = ko.pureComputed(() => {
    return self.order().anlass() == undefined || self.order().anlass() == "";
  });

  self.check_song_details_has_errors = () => {
    return (
      self.who_is_it_from_error() ||
      self.who_is_it_for_error() ||
      self.song_story_error() ||
      self.song_important_error() ||
      self.anlass_error()
    );
  };

  self.song_details_next_click = () => {
    if (!self.check_song_details_has_errors()) {
      self.show_errors(false);
      console.log("SONG DETAILS NEXT -- NO ERRORS");

      self.order().save_to_cookie(1);
      //Do something?
      pager.navigate("order/step2");

      const langModal = new bootstrap.Modal(
        document.getElementById("modalLangSwitch"),
        {}
      );

      //langModal.show();

      // Don't want to do normal form submission
      return false;
    } else {
      self.show_errors(true);
      return false;
    }
  };

  // Page 2
  self.genre_error = ko.pureComputed(() => {
    return self.order().genre() == undefined || self.order().genre() == "";
  });

  self.language_error = ko.pureComputed(() => {
    return (
      self.order().song_language() == undefined ||
      self.order().song_language() == ""
    );
  });

  self.stimmung_error = ko.pureComputed(() => {
    return (
      self.order().stimmung() == undefined || self.order().stimmung() == ""
    );
  });

  self.artist_selection_error = ko.pureComputed(() => {
    return (
      self.order().selected_artist() == undefined ||
      self.order().selected_artist() == ""
    );
  });

  self.check_artist_genre_has_errors = () => {
    return (
      self.genre_error() ||
      self.language_error() ||
      self.stimmung_error() ||
      self.artist_selection_error()
    );
  };

  self.artist_genre_next_click = () => {
    if (!self.check_artist_genre_has_errors()) {
      self.show_errors(false);
      console.log("Last Field NEXT -- NO ERRORS");

      self.order().save_to_cookie(2);
      //Do something?
      pager.navigate("order/step3");

        console.log("MAYBE FBQ");



      // Don't want to do normal form submission
      return false;
    } else {
      self.show_errors(true);
      return false;
    }
  };

  // Page 3
  self.noch_etwas_next_click = () => {
    self.show_errors(false);

    if(self.order().email()){
       self.bd_component_vm().email(self.order().email());
    }
    if(self.order().first_name()){
       self.bd_component_vm().billing_details().first_name(
            self.order().first_name());
    }



    self.order().save_to_cookie(3);

    //Do something?
    pager.navigate("order/step4");

    self.save_progress_step_three();

        try{
            self.log_event('AddToCart');
            if(typeof fbq != 'undefined')
            {
                fbq('track', 'AddToCart',
                    {'value': self.total_amount_to_pay(),
                     'currency': 'EUR',
                     'content_name':self.order().selected_artist(),
                     'content_ids': [self.order().selected_artist()],
                     'content_type':"Artist"});
            }

            if(typeof ttq != 'undefined')
            {
                ttq.track('AddToCart',{
                    contents:[{
                    'content_name': self.order().selected_artist(),
                    'content_id': self.order().selected_artist(),
                    'content_category': "Artist"
                    }],
                    "value": self.total_amount_to_pay(),
                    "currency" : "EUR"
				});
            }

            if( typeof pintrk != 'undefined'){
                pintrk('track', 'addtocart', {
                    currency: 'EUR',
                    value: self.total_amount_to_pay(),
                    order_quantity: 1,

                    line_items: [{
                    'product_name': self.order().selected_artist(),
                    'product_id': self.order().selected_artist(),
					'product_category': "Artist",
                    'product_price': self.total_amount_to_pay(),
                    }]
                });
            }



            if (typeof(ga) != 'undefined') {
              // SG_AddToCart
              ga('event', 'conversion', {
                  'send_to': 'AW-923598552/o8F0COSqzpIZENj9s7gD',
                  'value': self.total_amount_to_pay(),
                  'currency': 'EUR',
              });
          }

        }catch(e){
            console.error(e)
        }



    // Don't want to do normal form submission
    return false;
  };

  // Page 4
  self.terms = ko.observable(false);
  self.terms_error = ko.pureComputed(function () {
    return !self.terms();
  });

  self.show_gift_code_section = ko.observable(false);



  self.confirm_next_click = () => {
    if (!self.terms_error()) {
      self.show_errors(false);
      self.order().save_to_cookie(4);

      pager.navigate("order/step5");
      self.log_event('initiate');



      //console.log('continue to billing details');
    } else {
      self.show_errors(true);
      return false;
    }
  };

  self.giftcode_success = ko.observable(false);
  self.gift_error = ko.observable();


  self.apply_gift_code = function(){
    console.log("AMOUNT ", self.total_amount_to_pay());
    self.gift_error(null)

    var data = {
        gift_card_code : self.order().giftcode(),
        amount: self.total_amount_to_pay()
    }

    return $.ajax({
        url: '/api/apply-gift-code',
        type: 'POST',
        contentType: "application/json; charset=utf-8",
        data: ko.toJSON(data),

        processData: false,

        success: function (data) {
           if(data.success == true){
                self.giftcode_success(true)
                if(data.discount_code_uuid){
                    self.order().gift_card_uuid(data.discount_code_uuid);
                    self.order().gift_type('discount_code');
                }
                else if(data.gift_card_uuid){
                    self.order().gift_card_uuid(data.gift_card_uuid);
                    self.order().gift_type('gift_card');
                }
                let artist = ko.utils.arrayFirst(self.artists(), (item) => {
                  return item.username == self.order().selected_artist();
                });

                self.amount(artist.price - data.amount);
                self.order().gift_amount(data.amount);
            }
            else{
                self.order().gift_card_uuid(undefined);
                self.gift_error('Gutschein code did not work');
            }
        },
        error: function (data) {
            self.gift_error('Gutschein code did not work');
        }
    });
  }


  // Page 5 - Billing

  self.full_name = ko.observable();
  self.address = ko.observable();
  self.address_google_complete = ko.observable(false);

  self.payment_method = ko.observable();
  self.credit_method = ko.observable();
  self.selected_payment_method = ko.observable();

  self.shipping_address = ko.observable();
  self.shipping_name = ko.observable();
  self.shipping_address_google_complete = ko.observable(false);

  self.bd_component_vm = ko.observable();

  self.billing_details = ko.observable(
    new BillingDetails({ rootvm: self.rootvm })
  );
  // pre populate
  self.billing_details().legal_form("individual");
  self.legal_form_options = ko.observableArray(LEGALFORM_TYPES);
  self.country_options = ko.observableArray([]);

  self.purchasing = ko.observable(false);
  self.purchased = ko.observable(false);
  self.routing_to_payment = ko.observable(false);

  self.show_company_name = ko.computed(function () {
    return (
      self.billing_details().legal_form() != undefined &&
      (self.billing_details().legal_form() == "company" ||
        self.billing_details().legal_form() == "organization")
    );
  });

  self.amount = ko.observable(240);
  self.total_amount_to_pay = ko.pureComputed(function () {
    let artist = ko.utils.arrayFirst(self.artists(), (item) => {
      return item.username == self.order().selected_artist();
    });
    if (artist) {
      return artist.price;
    }
  });

  self.full_name_error = ko.computed(function () {
    return self.full_name() == undefined || self.full_name() == "";
  });

  self.first_name_error = ko.computed(function () {
    return (
      self.billing_details().first_name() == undefined ||
      self.billing_details().first_name() == ""
    );
  });

  self.last_name_error = ko.computed(function () {
    return (
      self.billing_details().last_name() == undefined ||
      self.billing_details().last_name() == ""
    );
  });

  self.legal_form_error = ko.computed(function () {
    return self.billing_details().legal_form() == undefined;
  });

  self.company_name_error = ko.computed(function () {
    return (
      self.show_company_name() &&
      (self.billing_details().company() == "" ||
        self.billing_details().company() == undefined)
    );
  });

  self.nationality_error = ko.computed(function () {
    return (
      self.billing_details().nationality() == "" ||
      self.billing_details().nationality() == undefined
    );
  });

  self.birthday_error = ko.computed(function () {
    return (
      self.billing_details().birthday() == "" ||
      self.billing_details().birthday() == undefined
    );
  });

  self.payment_method_error = ko.computed(function () {
    return (
      self.selected_payment_method() == undefined ||
      self.selected_payment_method() == ""
    );
  });

  self.check_billing_has_error = () => {
    return self.bd_component_vm().check_has_error();
  };

  self.buy_next_click = () => {
    if (!self.check_billing_has_error()) {
      self.show_errors(false);
      self.bd_component_vm().show_errors(false);
      self.bd_component_vm().save_to_cookie();

      self.begin_payment();
      // Do something?
      // Submit AJAX and get checkout
      // pager.navigate('order/step3');

      // Don't want to do normal form submission
      return false;
    } else {
      self.show_errors(true);
      self.bd_component_vm().show_errors(true);
      return false;
    }
  };

  // Our Tiny Artist Slider
  self.slider = undefined;
  self.begin_payment = function () {
    if (self.is_busy()) {
      return false;
    }

    // Turn off before unload
    $(window).off('beforeunload');


    logEvent(self.rootvm.web_host, 'payment_started',
       window.location.pathname, ko.toJSON({'artist': self.order().selected_artist(),
        'email': self.bd_component_vm().email()}));

    try{
          if(typeof fbq != 'undefined')
          {
            fbq('track', 'InitiateCheckout',
                {'value': self.total_amount_to_pay(),
                 'currency': 'EUR',
                 'content_name':self.order().selected_artist(),
                 'content_ids': [self.order().selected_artist()],
                 'content_type':"Artist"});
          }

          if(typeof ttq != 'undefined')
          {
             ttq.track('InitiateCheckout',{
                contents:[{
                'content_name': self.order().selected_artist(),
                'content_id': self.order().selected_artist(),
                'content_category': "Artist"
                }],
                "value": self.total_amount_to_pay(),
                "currency" : "EUR"
				});
          }

          if(typeof pintrk != 'undefined')
          {
            pintrk('track', 'checkout', {
                currency: 'EUR',
                value: self.total_amount_to_pay(),
                order_quantity: 1,

                line_items: [{
                'product_name': self.order().selected_artist(),
                'product_id': self.order().selected_artist(),
                'product_category': "Artist",
                'product_price': self.total_amount_to_pay(),
                }]
            });
          }


          if (typeof(ga) != 'undefined') {
              // SG_BeginCheckout
              ga('event', 'conversion', {
                  'send_to': 'AW-923598552/LjUwCKjmwZIZENj9s7gD',
                  'value': self.total_amount_to_pay(),
                  'currency': 'EUR',
              });

          }
    }catch(e){
            console.error(e)
    }




    //self.purchasing_gift_card(true);
    self.routing_to_payment(true);

    let return_url = window.location.href.split("?")[0];
    return_url = return_url.replace("step5", "check-payment");

    var data = {
      email_address: self.bd_component_vm().email(),
      billing_details: self.bd_component_vm().billing_details(),
      song_order: self.order(),
      invoice_phone: self.bd_component_vm().phone(),
      payment_method: self.selected_payment_method(),
      return_url: return_url,
      artist_name: self.order().selected_artist(),
    };

    if(self.order().gift_type() == 'gift_card'){
        data['gift_card_uuid'] = self.order().gift_card_uuid()
    }
    else if(self.order().gift_type() == 'discount_code'){
        data['discount_code_uuid'] = self.order().gift_card_uuid()
    }



    data["song_order"].rootvm = null;

    self.error("");
    self.is_busy(true);

    return $.ajax({
      url: "/api/song/begin-payment",
      type: "POST",
      contentType: "application/json; charset=utf-8",

      data: ko.toJSON(data),

      complete: function (data) {
        self.is_busy(false);
        //self.purchasing_gift_card(false);
      },

      success: function (data) {
        if (data.success) {
          // Send facebook event


          if (self.selected_payment_method() != "bankwire") {
            window.location = data.redirect_url;
          } else {
            self.payin_result_code("000000");
            self.payment_success(true);
            if (self.payment_success()) {
              self.purchased(true);
              self.order().reset();
              self.order().gys_order_uuid(data.gys_order_uuid);
              $("body").scrollTop(0, 0);
            }
            self.routing_to_payment(false);
            self.is_busy(false);
            //self.show_billing(false);
            pager.navigate("/order/check-payment");
          }
        } else {
          self.error(data.message);
          self.is_busy(false);
          self.routing_to_payment(false);
        }
      },
      error: function (data) {
        self.purchasing(false);
        var x;
        try {
          x = JSON.parse(data.responseText);
        } catch (err) {
          console.log("exception");
        }

        if (x) {
          self.error(x.message);
        } else {
          self.error("Sorry something went wrong! Please contact support@songgefluester.de for help. ");
        }
        self.show_errors(true);
        self.is_busy(false);
        self.routing_to_payment(false);
      },
    });
  };

  self.check_payment_status = function () {
    self.purchasing(true);

    var data = {
      transactionId: self.transactionId(),
    };

    self.error("");
    self.is_busy(true);

    return $.ajax({
      url: "/api/song-order/check-payment",
      type: "GET",
      contentType: "application/json; charset=utf-8",

      data: data,

      complete: function (data) {
        self.is_busy(false);
        self.purchasing(false);
      },

      success: function (data) {
        if (data.success) {
          self.payin_result_code(data.payin_result_code);
          self.payment_success(data.payin_result_message == "Success");
          if (self.payment_success()) {
            self.purchased(true);

            self.log_event('purchase');

			if (typeof(ga) != 'undefined') {
              // SG_Purchase (website)
              ga('event', 'conversion', {
                  'send_to': 'AW-923598552/LjUwCKjmwZIZENj9s7gD',
                  'value': data.amount ,
                  'currency': 'EUR',
				  'transaction_id': data.gys_order_uuid

              });

            }

            if(typeof fbq != 'undefined')
            {
            	fbq('track', 'Purchase',{
                            value: data.amount,
				            content_name:data.artist_uuid,
                            currency: 'EUR'});
            }

            if(typeof ttq != 'undefined')
            {
                ttq.track('CompletePayment',{
                contents:[{
                'content_name': data.artist_uuid,
                'content_id': data.artist_uuid,
                'content_category': "Artist"
                }],
                    "value": data.amount,
                    "currency" : "EUR"
				});
            }


			// Reset order
            self.order().reset();
            self.order().gys_order_uuid(data.gys_order_uuid);


          }
          if (data.payin_status == "CREATED") {
            self.purchasing(true);

            // Do this in a time out again in 1 second
            setTimeout(function () {
              self.check_payment_status();
            }, 2000);
          } else {
            self.purchasing(false);

          }

          if (data.emails) {
            //window.open(data.emails[0], 'blank');
            //window.open(data.emails[1], 'blank');
          }
        } else {
          self.error(data.message);
          self.is_busy(false);
        }
      },
      error: function (data) {
        self.purchasing(false);
        console.log("error checking payment", data);
        var x = JSON.parse(data.responseText);
        if (x) {
          self.error(x.message);
          self.is_busy(false);
        }
      },
    });
  };

  self.stepsAfterShow = function (page_index) {
    self.page_index(page_index + 1);
  };
  self.page_index = ko.observable(undefined);

  self.buildSlider = function (slider_id) {
    // the cloned elements are calling this function with undefinded artist_uuid
    if (self.slider) {
      self.slider.rebuild();
      console.log("rebuild");
    } else {
      self.slider = tns({
        container: "#" + slider_id,
        nav: false,
        mouseDrag: true,
        touch: true,
        loop: false,
        rewind: true,
        controlsText: [
          '<i class="bi bi-arrow-left"></i>',
          '<i class="bi bi-arrow-right"></i>',
        ],
        responsive: {
          0: { items: 1, gutter: 12 },
          992: { items: 2, gutter: 20 },
          1200: { items: 3, gutter: 28 },
        },
      });
    }
    const index = self.filtered_artists().findIndex((item) => {
      return item.username == self.order().selected_artist();
    });
    if (self.slider && index >= 0 && index !== self.slider.getInfo().index) {
      self.slider.goTo(index);
    }
  };

  self.log_event = (event_name) =>{
    if (typeof(window.ttq) !== 'undefined'){
        if(event_name == 'initiate'){
            window.ttq.track("InitiateCheckout");
        }
        if(event_name == 'payment'){
            window.ttq.track('AddPaymentInfo')
        }
        if(event_name == 'purchase'){
            window.ttq.track('CompletePayment')
        }
    }
  }

  self.email_save_valid = ko.pureComputed( () => {
      return validateEmail(self.email_save_progress());
  });

  self.check_email_save_progress_has_error = () => {

      if(self.email_save_progress() == '' ||
          self.email_save_progress() == undefined){
            return true;
      }

      if(!self.email_save_valid()){
          return true;
      }
      if(self.email_save_progress() != self.email_save_progress_confirm()){
          return true;
      }

      if(self.terms_error()){
        return true;
      }

      return false;
  };

  self.save_progress_step_three = () => {
    return $.ajax({
        url: '/api/order/save-progress',
        type: 'POST',
        contentType: "application/json; charset=utf-8",
        data: ko.toJSON({'email': self.order().email(),
           'order_data_json': self.order().order_data_as_dict(),
           'send_emails': false }),

        processData: false,

        success: function (data) {
           if(data.success == true){
                setCookie('GYS_save_progress', true);

                //self.email_save_progress_submitted(true);
                // turn off the save
                $(window).off('beforeunload', self.maybe_open_save_progress_modal);

            }
        },
        error: function (data) {
            console.log(data);
        }
    });



  }

  self.save_progress_email_post = () => {
    self.show_errors(false);

    if(self.check_email_save_progress_has_error()){
        self.show_errors(true);
        return false;
    }

    // Check to make sure emails match and we don't have an error


    return $.ajax({
        url: '/api/order/save-progress',
        type: 'POST',
        contentType: "application/json; charset=utf-8",
        data: ko.toJSON({'email': self.email_save_progress(),
           'order_data_json': self.order().order_data_as_dict() }),

        processData: false,

        success: function (data) {
           if(data.success == true){
                setCookie('GYS_save_progress', true);

                self.email_save_progress_submitted(true);
                // turn off the save
                $(window).off('beforeunload', self.maybe_open_save_progress_modal);

            }
        },
        error: function (data) {
            self.gift_error('Gutschein code did not work');
        }
    });
  }

  self.maybe_open_save_progress_modal = () =>{

      // check for cookie
      var saveProgress = getCookie('GYS_save_progress');
      console.log("Value of saveProgress Cookie ", saveProgress);

      if(self.selected_payment_method()){
        console.log("routing to payment");
        return false;
      }

      if(saveProgress === null && self.order().song_story() !== undefined
            && self.order().song_story() !== ""){
          // WE NEED TO SETUP THE onbeforeunload handler
          if(self.saveModal === undefined){
              self.saveModal = new bootstrap.Modal(
                document.getElementById("modalSaveProgress"),
                {}
              );
          }
          self.saveModal.show();
          return "Are you sure?";
      }
      else{
          return null;
      }
  }
}
