import { Collapse } from "bootstrap";
const navbar = document.querySelectorAll('.navbar');
const body = document.body;

navbar.forEach(function (element) {
    element.addEventListener('hide.bs.collapse', function () {
        body.classList.remove('navbar-active');
    })
    element.addEventListener('show.bs.collapse', function () {
        setTimeout(() => {
            body.classList.add('navbar-active');
        }, 0)
    })
})

// hide the nav menu on click
const navLinks = document.querySelector('.navbar-collapse');
if(navLinks){
    navLinks.addEventListener('click', function (e) {
        if ((e.target.tagName === "A") && (getComputedStyle(this).display !== 'flex')) {
            new Collapse(this).hide
        }
    })
}
