/*
 * Static page js for our order page?
 */
import { setupJqueryHistory } from "./js-modules/common/jquery.history"

// Styles

// External Packages
//import moment from 'moment';
//import {validate as uuidValidate} from 'uuid';
//import modals from 'bootstrap';
import pagerjs from 'pagerjs';

//Globals that should really be imported
//window.uuidValidate = uuidValidate;

// Always need the template loader for components
import { setCookie, getCookie, templateFromUrlLoader, koBindingsSetup } from "./js-modules/common/junkdrawer";

//ViewModels
import {GYSBaseViewModel} from "./js-modules/viewmodels/gysbasevm";
import {GYSOrderViewModel} from "./js-modules/viewmodels/ordervm";
import {GYSDeliveryViewModel} from "./js-modules/viewmodels/deliveryvm";
import { GYSContactViewModel } from "./js-modules/viewmodels/contactvm";
import { GYSArtistSettingsViewModel} from "./js-modules/viewmodels/artistsettingsvm";


// Load up our template loader
ko.components.loaders.unshift(templateFromUrlLoader);

//Components

import  "./js-modules/components/form-select";
import  "./js-modules/components/form-input";
import  "./js-modules/components/form-textarea";
import  "./js-modules/components/form-checkbox";
import  "./js-modules/components/form-radio";
import  "./js-modules/components/form-multiselect";
import  "./js-modules/components/form-select";

import  "./js-modules/components/billing-details";
import  "./js-modules/components/artist-tile";
import  "./js-modules/components/song-uploader";

import  "./js-modules/components/occasion-tile";

import  "./js-modules/components/cookie-consent";
/*
import  "./js-modules/components/location-search-box";
import  "./js-modules/components/artist-search-box";
import  "./js-modules/components/artist-search-box-modal";
import  "./js-modules/components/event-list";
import  "./js-modules/components/modal-simple";
import  "./js-modules/components/header-image";
import  "./js-modules/components/artist-tile";
import  "./js-modules/components/host-tile";
import  "./js-modules/components/blog-tile";
import  "./js-modules/components/blog-author";
import  "./js-modules/components/request-list";
import  "./js-modules/components/page-loading-indicator";
import  "./js-modules/components/event-date-selector";
import  "./js-modules/components/choose-fee";
import  "./js-modules/components/image-tile"

import  "./js-modules/components/artist-profile-infobox";
import  "./js-modules/components/artist-profile-actionbox";
import  "./js-modules/components/artist-profile-concertbox";
import  "./js-modules/components/artist-profile-media-carousel";
import  "./js-modules/components/host-profile-infobox";
import  "./js-modules/components/host-profile-activitybox";
import  "./js-modules/components/host-profile-concertbox";

import  "./js-modules/components/concert-tile";
import  "./js-modules/components/concert-profile-details";
import  "./js-modules/components/concert-profile-preview";
import  "./js-modules/components/concert-profile-guest-list";
import  "./js-modules/components/concert-profile-my-guest-list";

import  "./js-modules/components/tile-list";
*/




// setup i18next binding
window.jQuery = $;
// History adapter binding needs window to have jquery
setupJqueryHistory(window);
// setup other knockout bindings
koBindingsSetup();
// We'll need to set up all / other binding handlers here


// This is like the main -- run it to start everything up
$(document).ready(function(){
    console.log('In document ready');
   	$.ajaxSetup({ cache: false });

    //Globals are defined in base.html as well as [lang].js
    //moment.locale('de');

    pager.useHTML5history = true;
    pager.Href5.history = History;

    const gysvm= new GYSBaseViewModel({
        web_host:window.sc_web_host,
        frontend_web_host:window.sc_frontend_web_host,
        frontend_web_version:window.sc_frontend_web_version,
        image_path_prefix:window.sc_image_path_prefix,
        session: window.sc_session,
        is_prod:window.sc_is_prod,
        currency: 'EUR'});


    if (window.gys_order !== undefined){
        gysvm.deliveryvm = new GYSDeliveryViewModel(
            {
             rootvm:gysvm,
             order:window.gys_order,
             perspective: window.gys_order_perspective});
    }
    else if (window.contact === true) {
        gysvm.contactvm = new GYSContactViewModel({rootvm:gysvm, contactType:window.contactType});
    }
    else if(window.gys_artist_settings !== undefined){
        gysvm.artistsettingsvm = new GYSArtistSettingsViewModel({rootvm:gysvm,
            settings: window.gys_artist_settings})
    }
    else{
        gysvm.ordervm = new GYSOrderViewModel({rootvm:gysvm});
        //gysvm.homevm = new GYSHomeViewModel({rootvm:gysvm});
    }





    /* This is the view model used for the individual page
    gysvm.artistprofilevm = new ArtistProfileViewModel({
        rootvm: gysvm,
    });*/

    pager.extendWithPage(gysvm);
    ko.applyBindings(gysvm);

    console.log("DONE WITH APPLY BINDINGS");
    pager.startHistoryJs();
    gysvm.initialize();

    console.log("DONE WITH INIT")
    window.scvm = gysvm;
    /*
    try {
        if (getCookie('gysLpage') === null) {
            setCookie('gysReferrer', document.referrer);
            setCookie('gysLpage', window.location.href);
        }
    } catch (error) {
        console.log('cookie storage error');
    }*/



	// Make our i18nextko translation context global
});
