export function GYSContactViewModel(data) {
  var self = this;
  self.type = "GYSContactViewModel";

  self.ko = ko;

  self.rootvm = data.rootvm;
  self.contact_type = data.contactType;

  self.name = ko.observable();
  self.email = ko.observable();
  self.phone = ko.observable(); // contentType: 'question' or 'b2b-request'
  self.artist_name = ko.observable(); // only contentType: 'artist-application'
  self.profile_link = ko.observable(); // only contentType: 'artist-application'
  self.subject = ko.observable(); // only contentType: 'b2b-request'
  self.message = ko.observable();
  self.accept_terms = ko.observable(false);

  self.is_busy = ko.observable(false);
  self.initialized = ko.observable(false);

  self.error = ko.observable();
  self.show_errors = ko.observable(false);
  self.show_success = ko.observable(false);
  self.initialize = () => {
    //console.log("Whatever we need to do to initialize we can do here");
    self.initialized(true);
  };

  self.has_errors = ko.pureComputed(() => {
    let contact_type_specific_requirments_fulfilled = false
    if (self.contact_type === 'artist-application') {
      contact_type_specific_requirments_fulfilled = self.artist_name() && self.profile_link();
    }
    if (self.contact_type === 'b2b-request') {
      contact_type_specific_requirments_fulfilled = self.subject()
    }
    return (
      !contact_type_specific_requirments_fulfilled && !self.name() && !self.email() && !self.message() && !self.accept_terms()
    );
  });
  self.form_submitted = ko.observable(false);
  self.form_submitted.subscribe((newValue) => {
    if (newValue) {
      self.name(undefined);
      self.email(undefined);
      self.phone(undefined);
      self.artist_name(undefined);
      self.profile_link(undefined);
      self.subject(undefined);
      self.message(undefined);
      self.accept_terms(false);
    }
  });
  self.submitForm = function () {
    // form complete?
    self.error(undefined);
    self.show_errors(false);
    self.show_success(false);
    if (self.has_errors() === true) {
      self.error("Please fill out all required fields");
      self.show_errors(true);
      return false;
    }
    // send form
    self.is_busy(true);
    return $.ajax({
      url: self.rootvm.web_host + "/contact/submit",
      type: "POST",
      processData: false,
      data: ko.toJSON({
        contact_type: self.contact_type,
        name: self.name(),
        email: self.email(),
        phone: self.phone(),
        artist_name: self.artist_name(),
        profile_link: self.profile_link(),
        subject: self.subject(),
        message: self.message(),
      }),

      contentType: "application/json; charset=utf-8",

      success: function (data) {
        self.form_submitted(true);
        self.show_success(true);
      },
      error: function (data) {
        console.log(data);
      },
      complete: function () {
        self.is_busy(false);
      },
    });
  };
}
