// Constants
//
export const LEGALFORM_TYPES = [
    {value:"individual",
        label:'natürliche Person'},
    {value:"company",
        label:'Unternehmen'},
    {value:"organization",
        label:'Organisation'},
    {value:"entrepreneur",
        label:'Einzelunternehmer'}
];

export const TAXRATE_TYPES = [
    {value:"smallbusiness", label: 'profile.artist.invoice.taxrate.smallbusiness', percentage: 0, country_abr: 'de'},
    {value:"frreduced", label: 'profile.artist.invoice.taxrate.frreduced', percentage: 5.5,country_abr: 'fr'},
    {value:"nlreduced", label: 'profile.artist.invoice.taxrate.nlreduced', percentage: 6, country_abr : 'nl'},
    {value:"artistreduced", label: 'profile.artist.invoice.taxrate.artistreduced', percentage: 7, country_abr: 'de'},
    {value:"swiss", label: 'profile.artist.invoice.taxrate.swiss', percentage: 7.7, country_abr: 'ch'},
    {value:"spain", label: 'profile.artist.invoice.taxrate.spain', percentage: 10,
        country_abr: 'es'},
    {value:"austriareduced", label: 'profile.artist.invoice.taxrate.austriareduced', percentage: 13, country_abr: 'at'},
    {value:"austriaregular", label: 'profile.artist.invoice.taxrate.austriaregular', percentage: 20, country_abr: 'at'},
    {value:"fulltax", label: 'profile.artist.invoice.taxrate.fulltax', percentage: 19, country_abr: 'de'},
    {value:"frenchtax", label: 'profile.artist.invoice.taxrate.frenchtax', percentage: 0, country_abr: 'fr'},
    {value:"us-default", label: 'profile.artist.invoice.taxrate.usdefault', percentage: 0, country_abr: 'us'},
    {value:"ca-default", label: 'profile.artist.invoice.taxrate.cadefault', percentage: 0, country_abr: 'ca'},
    {value:"custom", label: 'profile.artist.invoice.taxrate.custom', percentage: 0,
        country_abr: 'all'},

];

export const VIDEO_MESSAGE_PRICES = [
    {value:45, short_label: '45 €', label: 'video_message_checkout.prices.45', biz: false},
    {value:55, short_label: '55 €', label: 'video_message_checkout.prices.55', biz: false},
    {value:75, short_label: '75 €', label: 'video_message_checkout.prices.75', biz: false},
    {value:100, short_label: '100 €', label: 'video_message_checkout.prices.100', biz: false},
    {value:150, short_label: '150 €', label: 'video_message_checkout.prices.150', biz: false},
    {value:200, short_label: '200 €', label: 'video_message_checkout.prices.200', biz: false},
    {value:250, short_label: '250 €', label: 'video_message_checkout.prices.250', biz: false},
    {value:250, short_label: '250 €', label: 'video_message_checkout.biz_prices.250', biz: true},
    {value:400, short_label: '400 €', label: 'video_message_checkout.biz_prices.400', biz: true},
    {value:750, short_label: '750 €', label: 'video_message_checkout.biz_prices.750', biz: true},
];

export const NO_OPTIONS = [
    {label: 'modal.denyreason.artist.wrongdate', perspective: 'artist'},
    {label: 'modal.denyreason.artist.othergig', perspective: 'artist'},
    {label: 'modal.denyreason.artist.noplans', perspective: 'artist'},
    {label: 'modal.denyreason.artist.other', perspective: 'artist'},
    {label: 'modal.denyreason.host.wrongdate', perspective: 'host'},
    {label: 'modal.denyreason.host.wronggenre', perspective: 'host'},
    {label: 'modal.denyreason.host.noplans', perspective: 'host'},
    {label: 'modal.denyreason.host.other', perspective: 'host'}
]


export const EQUIPMENT_TYPES = [
    {value:'yes', label: 'equipment.manage.yes'},
    {value:'maybe', label: 'equipment.manage.maybe'},
    {value:'no', label: 'equipment.manage.no'},
    {value:'unplugged', label: 'equipment.manage.unplugged'},
];

export const LODGING_TYPES = [
    {value:'yes', label: 'lodging.manage.yes'},
    {value:'maybe', label: 'lodging.manage.maybe'},
    {value:'no', label: 'lodging.manage.no'}
];

export const AVAILABILITY_TYPES = [
    {value:'anytime', label: 'available.anytime'},
    {value:'weekend', label: 'available.weekend'},
    //{value:'justnot', label: 'available.pause'},
    {value:'seenote', label: 'available.seenote'}
];

export const PAUSE_TYPES = [
    {
        value:'14',
        label: 'profile.host.availability.pause.period.2weeks'
    },
    {
        value:'28',
        label: 'profile.host.availability.pause.period.4weeks'
    },
    {
        value:'30',
        label: 'profile.host.availability.pause.period.thirty'
    },
    {
        value:'90',
        label: 'profile.host.availability.pause.period.ninety'
    },
    {
        value:'182', // FIXME: 182 or 180?
        label: 'profile.host.availability.pause.period.oneeighty'
    },
    {
        value:'365',
        label: 'profile.host.availability.pause.period.threesixtyfive'
    },
];

export const BOOKING_TYPES = [
    {value:"fixfee", label: 'general.feemode.fixfee'},
    {value:"hatwithguarantee", label: 'general.feemode.hatwithguarantee'},
    {value:"playingforhat", label: 'general.feemode.playingforhat'}
];


export const AGE_RANGES = [
    {value:'children', label: 'profile.host.about.agerange.children'},
    {value:'youth', label: 'profile.host.about.agerange.youth'},
    {value:'age18', label: 'profile.host.about.agerange.age18'},
    {value:'age25', label: 'profile.host.about.agerange.age25'},
    {value:'age35', label: 'profile.host.about.agerange.age35'},
    {value:'age45', label: 'profile.host.about.agerange.age45'},
];

export const ATMOSPHERE_TYPES = [
    {value: "happy", label: "profile.artist.music.atmosphere.happy"},
    {value: "dance", label: "profile.artist.music.atmosphere.dance"},
    {value: "calm", label: "profile.artist.music.atmosphere.calm"},
    {value: "festive", label: "profile.artist.music.atmosphere.festive"},
    {value: "lounge", label: "profile.artist.music.atmosphere.lounge"},
    {value: "experimental", label: "profile.artist.music.atmosphere.experimental"},
];

export const LINEUP_TYPES = [
    {value: "solo", label: "search.line_up.solo"},
    {value: "duo", label: "search.line_up.duo"},
    {value: "band" , label: "search.line_up.band"},
    {value: "ensemble", label: "search.line_up.ensemble"},
    {value: "DJ", label: "search.line_up.DJ"},
];

export const SET_OPTIONS = [
    {value: "original", label: "profile.artist.experience.set_options.original"},
    {value: "cover", label: "profile.artist.experience.set_options.cover"},
    {value: "requests" , label: "profile.artist.experience.set_options.requests"},
];


export const COPYRIGHT_TYPES = [
    {value: "gema", label: "profile.artist.experience.copyright.gema"},
    {value: "other", label: "profile.artist.experience.copyright.other"},
    {value: "SACEM" , label: "profile.artist.experience.copyright.sacem"},
    {value: "AKM" , label: "profile.artist.experience.copyright.akm"},
    {value: "SUISA" , label: "profile.artist.experience.copyright.suisa"},
    {value: "none" , label: "profile.artist.experience.copyright.none"}
]

export const GENRE_OPTIONS = [
        {value: "singer songwriter", label: "singer songwriter"},
        {value: "pop", label: "pop"},
        {value: "rock", label: "rock"},
        {value: "indie", label: "indie"},
        {value: "alternative", label: "alternative"},
        {value: "folk", label: "folk"},
        {value: "country", label: "country"},
        {value: "hiphop", label: "hiphop"},
        {value: "randb", label: "randb"},
        {value: "soul", label: "soul"},
        {value: "punk", label: "punk"},
        {value: "funk", label: "funk"},
        {value: "world music", label: "world music"},
        {value: "reggae", label: "reggae"},
        {value: "latin", label: "latin"},
        {value: "chanson", label: "chanson"},
        {value: "R&B", label: "R&B"},
        {value: "gospel", label: "gospel"},
        {value: "experimental", label: "experimental"},
        {value: "electronic", label: "electronic"},
        {value: "jazz", label: "jazz"},
        {value: "blues", label: "blues"},
        {value: "lounge", label: "lounge"},
        {value: "classical", label: "classical"},
        {value: "instrumental", label: "instrumental"},
        {value: "other", label: "other"},
];

export const EVENT_PRIVACY_OPTIONS = [
        {value: "public", label: "Public", icon: 'mdi-account-multiple'},
        {value: "invite", label: "Invite", icon: 'mdi-account-multiple-plus'},
        {value: "closed", label: "Closed", icon: 'mdi-account-key'},
        {value: "all", label: "Closed", icon: 'mdi-account-key'},
];

export const EVENT_TYPE_LINKS = {
    "de":{
        "sofaconcert": {
           "house": "/de/wohnzimmerkonzert",
           "house_warming": "/de/einweihungsparty-livemusik"
        },
        "birthday": {
            "house_concert" : "/de/geburtstagsfeier-livemusik",
            "surprise" : "/de/ueberraschung-livemusik"
        },
        "special_occassion":{
            "funeral": "de/trauerfeier",
            "commemoration": "de/trauerfeier",
            "hen_party" : "/de/junggesellinnenabschied-jga-livemusik"
        },
        "wedding":{
           "singer": "/de/trauung",
           "party" : "/de/hochzeitsfeier",
           "champagne": "/de/sektempfang",
           "walking_act": "/de/sektempfang",
        },
        "corporate_event":{
            "house_concert" : "/de/sofablog/inspiration/firmenfeier_teamevent/190604_teambuilding-mit-musik",
            "christmas_concert" : "/de/weihnachtsfeier-livemusik",
            "christmas_party" : "/de/weihnachtsfeier-livemusik",
            "festival"  : "/de/firmenevents-livemusik"
        },
        "events_surprise":{
            "opening" : "/de/livemusik-eventkonzepte-booking",
            "flashmob" : "/de/livemusik-eventkonzepte-booking"
        },
    },
    "en":{


    }
};

export const TIME_OPTIONS = [ '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '24:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30'];

export const SC_SOCIAL_SHARE_BASE_URLS = {
    'facebook':'https://www.facebook.com/sharer/sharer.php?u={{url}}',
    'messenger': 'fb-messenger://share?link={{url}}&app_id={{app_id}}',
    'twitter':'https://twitter.com/intent/tweet?text={{text}}&url={{url}}',
    'whatsapp': 'https://api.whatsapp.com/send?text={{text}}&url={{url}}',
    'pinterest':('http://www.pinterest.com/pin/create/button/'+
         '?url={{url}}&media={{image_url}}&description={{text}}')
}



