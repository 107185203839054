import Plyr from "plyr"


const elements = document.querySelectorAll('[data-video]');

elements.forEach(function (element) {
    console.log(element)
    new Plyr(element, {
        autoplay: false,
        youtube: {
            noCookie: true,
            rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1,
            preload: false
        },
        controls: [
            'play-large', // Play/pause playback
            'play', // Play/pause playback
            'progress', // The progress bar and scrubber for playback and buffering
            'current-time', // The current time of playback
            'duration', // The full duration of the media
            //'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
        ]
    });
});

const elementsAudio = document.querySelectorAll('[data-audio]');

elementsAudio.forEach(function (element) {
    new Plyr(element, {
        controls: [
            'play', // Play/pause playback
            'progress', // The progress bar and scrubber for playback and buffering
            'current-time', // The current time of playback
            'duration', // The full duration of the media
            //'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
        ]
    });
});