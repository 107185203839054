export function GYSArtistSettingsViewModel(data) {
  var self = this;
  self.type = "GYSArtistSettingsViewModel";

  self.ko = ko;

  self.rootvm = data.rootvm;

  self.artist_uuid = data.settings.artist_uuid;
  self.active = ko.observable(data.settings.active);
  self.price = ko.observable(data.settings.price);
  self.tax_rate = ko.observable(data.settings.tax_rate);

  self.fee = ko.pureComputed(() => -1 * self.price() * 0.3);
  self.earning = ko.pureComputed(() => self.price() + self.fee());

  self.show_netto = ko.observable(false);
  self.price_netto = ko.pureComputed(() => self.price() * 100 / (100+self.tax_rate()));
  self.fee_netto = ko.pureComputed(() => -1 * self.price() * 0.3 / 1.19 );
  self.earning_netto = ko.pureComputed(() => self.price_netto() + self.fee_netto());

  self.allow_netto_switch = ko.pureComputed(() =>{
    return self.tax_rate() != 0;
  });

  self.saved = ko.observable(undefined);

  self.save_settings = function () {
    console.log("Saving Settings!");
    self.saved(undefined);

    return $.ajax({
      url: "/api/artist/settings",
      type: "POST",
      data: ko.toJSON({
        artist_uuid: self.artist_uuid,
        active: self.active(),
        price: self.price(),
      }),
      contentType: "application/json; charset=utf-8",
      processData: false,

      success: function (data) {
        console.log("Saved");
        self.saved(true);
      },

      error: function (data) {
        self.error(data.statusText);
      },
    });
  };

  self.decrementPrice = () => {
    self.price() <= 200
      ? self.price(200)
      : self.price() < 350
      ? self.price.decrement(10)
      : self.price() < 800
      ? self.price.decrement(50)
      : self.price.decrement(100);
  };
  self.incrementPrice = () => {
    self.price() >= 4000
      ? self.price(4000)
      : self.price() < 350
      ? self.price.increment(10)
      : self.price() < 800
      ? self.price.increment(50)
      : self.price.increment(100);
  };

  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
  const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))
}
