ko.components.register('occasion-tile', {
  viewModel: function (params) {
    var self = this;
    self.type = "OccasionTileComponentViewModel";

    self.occasion = params.occasion;
    self.image = params.image;
    self.link = `/order?occasion=${self.occasion}`

    if(params.href){
        self.link = `/${params.href}`
    }

    // img src
    self.url_prefix = '//www.sofaconcerts.org/img/uploads';
    self.path_400w = '/content/';
    self.path_200w = '/preview/';
    self.img_srcset_webp = ko.pureComputed(function () {
      return self.url_prefix + self.path_200w + self.image + '.webp 200w, ' + self.url_prefix + self.path_400w + self.image + '.webp 400w';
    })
    self.img_srcset_jpg = ko.pureComputed(function () {
      return self.url_prefix + self.path_200w + self.image + '.jpg 200w, ' + self.url_prefix + self.path_400w + self.image + '.jpg 400w';
    })
    self.img_src_fallback = self.url_prefix + self.path_400w + self.image + '.jpg';

  },
  template: `
    <a data-bind="attr: {href: link}">
      <figure class="ratio ratio-3x2 figure">
        <div>
          <picture>
            <source type="image/webp" data-bind="attr: { 'srcset': img_srcset_webp }">
            <source type="image/jpg" data-bind="attr: { 'srcset': img_srcset_jpg }">
            <img class="figure-img img-fluid" data-bind="
                attr: { 'src': img_src_fallback, 'srcset': img_srcset_jpg, alt: occasion }">
          </picture>
          <figcaption class="figure-caption position-absolute bottom-0 py-1 text-center w-100"
            style="background-image: linear-gradient(to bottom, transparent 30%, #000000cc 100%);"
            data-bind="text: occasion"
          ></figcaption>
        </div>
      </figure>
    </a>
  `
});
