ko.components.register('artist-tile', {
  viewModel: function (params) {
    var self = this;
    self.type = "ArtistTileComponentViewModel"
    self.hi = ko.observable("real boy");

    if (params.artist.type == 'ArtistTileComponentViewModel') {
      const vm = params.artist;
      self.format = vm.format;
      self.artist = vm.artist;
      self.selectable = vm.selectable;
    }
    else {
      self.rootvm = params.rootvm;
      
      self.format = params.format || 'tall';
      self.artist = ko.observable(params.artist);

      self.selectable = params.selectable || false;
    }
  },
  template: { fromUrl: 'templates/artist-tile.html', maxCacheAge: 1234 }
});
