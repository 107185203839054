import {Song} from '../class/Order'

function SongUploaderComponentViewModel(params){
	var self = this;
	self.type= "SongUploaderComponentViewModel"

	self.open_file_chooser = function(){
		$('#' + self.uploaderInputID()).click();
        //self.get_put_url();
	}

	self.song = params.song;

    self.uploaderInputID = ko.observable(params.uploaderInputID);
    self.button_text = params.button_text;
    self.help_text = params.help_text;

	self.upload_progress = ko.observable(0);
	self.is_uploading = ko.observable(0);
	self.local_source = ko.observable();
	self.error = ko.observable();
	self.file = ko.observable(null);

    self.song_type = ko.pureComputed(function(){
        return self.uploaderInputID() == 'delivery-greeting' ? 'greeting' : 'song' ;
    });

    self.remove_callback = params.remove_callback;

    self.show_help_text = ko.observable(true);

    if(params.show_help_text != undefined){
      self.show_help_text(params.show_help_text);
    }


	// Uploads
	self.start_ajax_upload = function(me, evt){
		self.files = evt.target.files;

		for(var i = 0; i < evt.target.files.length; i++)
		{

			self.file(evt.target.files[i]);
			self.read_local_source();
			self.upload_file().then(self.insert_song);
		}
	};

    self.remove_song = function(){
        self.song(undefined);
        if(self.remove_callback != undefined){
            self.remove_callback();
        }
    };

	self.xhr_with_progress = function() {
		var xhr = new window.XMLHttpRequest();
		xhr.upload.addEventListener("progress",
			function(evt) {
				if (!evt.lengthComputable) return;
				var percentComplete = (evt.loaded / evt.total) * 100;
				self.upload_progress(Math.round(percentComplete) + '%');
			}, false);
		return xhr;
	};

	self.read_local_source = function(){
		// TODO We might not want to do this if the
		// file is too large...
		var fr = new FileReader();
		//First read as data url
		fr.onload = function (file_data) {
			//Make thumbnail show up
			self.local_source(file_data.target.result);
		}
		fr.readAsDataURL(self.file());
	};


    self.put_url = ko.observable('/api/upload/' + self.song_type());
    self.new_song_uuid = ko.observable();
    self.original_url = ko.observable();

    self.get_put_url = function(){
        self.put_url(undefined);
        self.new_photo_uuid(undefined);
        return $.ajax({
            url: '/api/get-song-upload-url',
            type: 'GET',

            contentType: "application/json; charset=utf-8",

            success: function (data) {

                //Everything is relative to EUR
                self.put_url(data.upload_url);
                self.original_url(data.download_url);
                self.new_song_uuid(data.photo_uuid);
            },

            error: function (data) {
                self.error(data.statusText);
            }
        });
    }


	self.upload_file = function () {
		self.is_uploading(true);

		return $.ajax({
			xhr: self.xhr_with_progress,
			url: decodeURIComponent(self.put_url()),
			type: 'PUT',
			data: self.file(),
            headers: {"Content-Type": "audio/mpeg"},
			cache: false,
			contentType: false,
            //crossDomain:true,
			processData: false,

			success: function (data) {

				self.is_uploading(false);
                console.log("Mp3 Upload complete");
				if(data.success){
					self.song(new Song(data.song));
				}
				else{
				}
			},

			error: function (data) {
				self.error(data.statusText);
			}
		});
	};

	self.insert_song = function () {
		return $.ajax({
			url: '/api/song',
			type: 'POST',
			data:ko.toJSON({
				song_uuid: self.song_uuid(),
				mimetype: self.file().type,
				filename: self.file().name,
                original_url : self.original_url(),
			}),
			contentType: "application/json; charset=utf-8",
			processData: false,

			success: function (data) {
				self.song(new Song(data.song))
			},

			error: function (data) {
				self.error(data.statusText);
			}
		});
	};


    // It's possible we might have a photo_obj instead
    if (params.photo_obj){
        self.song = ko.observable(params.photo_obj);
        self.check_photo_interval_id = setInterval(
            self.check_photo_still_processing, 1000);
    }

}

ko.components.register('song-uploader', {
    viewModel: {
        createViewModel: function(params, componentInfo){
           return new SongUploaderComponentViewModel(params);
        },
    },
    template: {fromUrl: 'templates/song-uploader.html', maxCacheAge:20181213}
});

