import {LEGALFORM_TYPES } from '../class/constants';
import { BillingDetails, Country } from '../class/Billing';
import {getCookie, setCookie, validateEmail} from '../common/junkdrawer';

import {Popover} from "bootstrap";

function BillingDetailsComponentViewModel(params){
	var self = this;
	self.type= "VideoMessageCheckoutComponentViewModel"

    self.rootvm = params.rootvm;
    self.parentvm = params.parentvm;

    params.bd_component_vm(this);


	self.is_busy = ko.observable(false);
    self.size = ko.observable(params.size || 'lg')
    console.log("Size of bd is ", self.size());

    self.payment_success = ko.observable();
    self.transactionId = params.transactionId;

    self.show_transfer = params.show_transfer || false;
    self.show_invoice = params.show_invoice || false;

    self.payin_result_code = ko.observable();
    self.i18nPaymentStatus = ko.computed(function(){
        if (self.payin_result_code()){
            return 'general.mangopay_result_code.' + self.payin_result_code();
        }
        else{
            return 'Payment Fail ' + self.payin_result_code();
        }
    });

    self.billing_details = params.billing_details;
    // maybe shipping
    self.shipping_address = params.shipping_address || ko.observable();
    self.shipping_name = params.shipping_name || ko.observable();
    self.shipping_address_google_complete = ko.observable(false);

    // gutschein

    self.selected_payment_method = params.selected_payment_method;

    self.payment_method = ko.observable();
	self.credit_method = ko.observable();

	self.payment_method_is_credit_card = ko.computed(function(){
		return self.payment_method() == 'creditcard'
	});

    self.show_shipping = ko.observable(false);

    console.log("SHOW SHIPPING IS ---- ", params.show_shipping);
    if(params.show_shipping === true){
        self.show_shipping(true);
    }

    self.shipping_help_text = ko.observable('general.invoice.shipping._');
    if(params.shipping_help_text){
        self.shipping_help_text(params.shipping_help_text);
    }

    self.error = ko.observable('');
    self.gift_error = ko.observable('');

	self.invoice_allowed = ko.computed(function(){
        return false;
	});

	self.transfer_allowed = ko.computed(function(){
        return false;
	});


    // Billing -- Prepopulate this if user is signed in -- use rootvm
    self.full_name = ko.observable();
    self.email = ko.observable();
    self.email_confirm = ko.observable();
    self.address = ko.observable();
    self.address_google_complete = ko.observable(false);
    self.phone = params.phone;

    if (self.phone == undefined){
        self.phone = ko.observable();
    }

    self.phone_label = params.phone_label || 'video_message_checkout.video_message.checkout.phone.title';
    self.phone_popover_text = params.phone_popover_text;
    self.phone_popover_title = params.phone_popover_title;


    // pre populate
	self.legal_form_options = ko.observableArray(LEGALFORM_TYPES);

    console.log(self.legal_form_options());
    self.country_options = ko.observableArray([]);

    self.billing_details().legal_form('individual');


	self.show_company_name = ko.computed(function(){
		return (self.billing_details().legal_form() != undefined) && (
			self.billing_details().legal_form() == 'company' ||
			self.billing_details().legal_form() == 'organization' )
	});


	self.initialize = function(){

        console.log('initing. billing details ');
        self.lookup_countries().then(self.load_from_cookie);

        // Maybe do popovers ?
        //

	   let popover3 = new Popover(
        document.querySelector('#billing-popover-phone'), {
             container: 'body'
       });
};

	self.source_loaded = function(element){
		if(google && google.maps && google.maps.places){
            self.google_autocomplete = new google.maps.places.Autocomplete(
                element);

            self.google_autocomplete.addListener ( 'place_changed',
                function(){self.update_address()});

		}
	};

	self.source_loaded_shipping = function(element){
        console.log("HOOKING UP SHIPPING ADDRESS")
   		if(google && google.maps && google.maps.places){
               var google_autocomplete = new google.maps.places.Autocomplete(
                    element);

               google_autocomplete.addListener ( 'place_changed',
                function(){
                    console.log('place changed shipping');
                    self.shipping_address(this.getPlace().formatted_address);
                self.shipping_address_google_complete(true)});
        }
	}



	self.update_address = function(place){
		if(!place){
			place = self.google_autocomplete.getPlace();
		}
		if(place){
			for (var i = 0; i < place.address_components.length; i++) {
			  var addressType = place.address_components[i].types[0];
			  if (self.billing_details().google_maps_components[addressType]) {
				var val = place.address_components[i][self.billing_details().google_maps_components[addressType]];
				self.billing_details().google_maps_mapping[addressType](val);
			  }
			}

			self.billing_details().billing_address(place.formatted_address);
            self.address_google_complete(true);
		}
	};

	self.lookup_place = function(address_observable, update_function){
		var service = new google.maps.places.PlacesService($('#hiddenGoogleMap')[0]);

		var request = {
			query: address_observable()
		};

		service.textSearch(request, function(results, status){

			  if (status == google.maps.places.PlacesServiceStatus.OK) {
				  var found_place = results[0];
				  self.lookup_place_id(found_place.place_id, service, update_function);
			  }
			  else{
				address_observable('');
			  }

		});
	}

	self.lookup_place_id = function(place_id, service, update_function){

		var request = {
		  placeId: place_id
		};

		service.getDetails(request, function (place, status) {
		  if (status == google.maps.places.PlacesServiceStatus.OK) {
    			update_function(place);
  		  }
		  else{
			  self.billing_details().full_pretty_address('');
          }
		});
	}



    //ERROR SECTION
    self.show_errors = ko.observable(false);

    self.billing_error = ko.observable(false);

    self.email_error = ko.pureComputed(function() {
        return self.email() == undefined ||
            self.email() == "" ||
            !validateEmail(self.email());
    });
    self.emailConfirm_error = ko.pureComputed(function() {
        return self.email_confirm() == '' ||
            self.email_confirm() == undefined ||
            self.email() != self.email_confirm()
    });


    self.full_name_error = ko.computed(function(){
        return self.full_name() == undefined ||
            self.full_name() == '';
    });

    self.first_name_error = ko.computed(function(){
        return self.billing_details().first_name() == undefined ||
            self.billing_details().first_name() == '';
    });

    self.last_name_error = ko.computed(function(){
        return self.billing_details().last_name() == undefined ||
            self.billing_details().last_name() == '';
    });

    self.legal_form_error = ko.computed(function(){
        return self.billing_details().legal_form() == undefined
    });

    self.company_name_error = ko.computed(function(){
        return (self.show_company_name() &&
            (self.billing_details().company() == '' ||
             self.billing_details().company() == undefined));
    });


    self.phone_error = ko.computed(function(){
        return self.phone() == '' ||
             self.phone() == undefined;
    });

    self.payment_method_error = ko.computed(function(){
        return self.selected_payment_method() == undefined ||
            self.selected_payment_method() == '';
    });




    self.address_error = ko.computed(function(){
        return !self.address_google_complete() ||
            self.billing_details().billing_address() == undefined ||
            self.billing_details().billing_address() == '' ||
            self.billing_details().billing_street() == undefined ||
            self.billing_details().billing_street_number() == undefined ||
            self.billing_details().billing_street() == '' ||
            self.billing_details().billing_street_number() == ''
    });

    self.shipping_address_error = ko.computed(function(){
        if(self.show_shipping() && self.shipping_name() != undefined && self.shipping_name() != ""){
            if(self.shipping_address() == undefined
                || self.shipping_address() == ''){
                self.shipping_address_google_complete(false);
            }
            return !self.shipping_address_google_complete();

        }else{
            return false;
        }
    });

    self.shipping_name_error = ko.computed(function(){
        if(self.show_shipping() && self.shipping_address_google_complete()){
            return self.shipping_name() == undefined ||
            self.shipping_name() == '';
        }
    });

    self.check_has_error = function(){
        self.show_errors(false);
        self.error('');

        if(self.email_error() || self.emailConfirm_error() ||
            self.first_name_error() ||
            self.legal_form_error() ||
            self.address_error() ||
            self.phone_error() ||
            self.shipping_address_error() ||
            self.shipping_name_error() ||
            self.company_name_error() ||
            self.payment_method_error()){

           self.show_errors(true);
           self.error('modal.error_obligation.body.obligatory_missing');
           return true;
        }
        else{
            return false;
        }
    };


	self.lookup_countries = function(){

        var url = self.rootvm.web_host + '/api/countries/de';

        return $.ajax({

            url: url,
            type: 'GET',
            cache: true,
            contentType: "application/json; charset=utf-8",

            success: function (data) {
                if(data.success){
					ko.utils.arrayForEach(data.countries, function(x){
					 	self.country_options.push(new Country(x));
					});
                }

            },
            error: function (data) {
   				self.billing_error(data.message);
            }
        });
    }

	self.maybe_lookup_google_place = () =>
    {
		if((self.billing_details().full_pretty_address() != '' &&
			self.billing_details().full_pretty_address() != undefined) &&
			!self.billing_details().billing_street()){
			self.lookup_place(self.billing_details().full_pretty_address,
				self.update_address);
		}
		else{
			self.address_google_complete(true);
		}
	}

    self.save_to_cookie = () => {
        // Save this order as a cookie
        console.log("Saving order progress as a cookie");

        let page = {};
        page['phone'] = self.phone();
        page['email'] = self.email();
        page['email_confirm'] = self.email_confirm();
        page['billing_details'] = self.billing_details();

        var data = ko.toJSON(page);
        setCookie('GYS_billing', JSON.stringify(data));
    }

    self.load_from_cookie = () => {
        console.log("Loading from Cookie");
        // PAGE 1
        let bd_data = JSON.parse(getCookie('GYS_billing'));
        if(bd_data){
            let parsed = JSON.parse(bd_data);
            self.billing_details().update_self(parsed.billing_details);


            self.phone(parsed.phone);
            self.email(parsed.email);
            self.email_confirm(parsed.email_confirm);

			self.maybe_lookup_google_place();
        }
    }


    self.apply_gift_code = function(){
		self.gift_error(null)

        var data = {
            gift_card_code : self.giftcode()
        }

        return $.ajax({

            url: '/api/apply-gift-code',
            type: 'POST',
            contentType: "application/json; charset=utf-8",
            data: ko.toJSON(data),

            processData: false,

            success: function (data) {
               if(data.success == true){
                    self.giftcode_success(true)
                    if(data.discount_code_uuid){
                        self.gift_card_uuid(data.discount_code_uuid);
                    }
					self.amount(self.amount() - data.amount);
                }
                else{
                    self.gift_card_uuid(undefined);
                	self.gift_error('Gutschein code did not work');
                }
            },
            error: function (data) {
                self.gift_error('Gutschein code did not work');
            }
        });
    }



}

ko.components.register('billing-details', {
    viewModel: {
        createViewModel: function(params, componentInfo){
            var vm = new BillingDetailsComponentViewModel(params);
            //vm.source_loaded($(componentInfo.element));

            vm.initialize();
            return vm;
        },
    },
    template: {fromUrl: 'templates/billing-details.html', maxCacheAge:220201213,
        needsGoogle:true}
});

