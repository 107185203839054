import ko from 'knockout';
import { TAXRATE_TYPES } from "./constants";

export function BillingDetails(data) {

    var self = this;
    self.type = "BillingDetails"

    self.billing_details_uuid = ko.observable(data.billing_details_uuid);
    self.first_name = ko.observable(data.first_name);
    self.last_name = ko.observable(data.last_name);
    self.full_pretty_address = ko.observable(data.full_pretty_address);

    self.company = ko.observable(data.company);
    self.billing_address = ko.observable(data.billing_address);
    self.billing_address_line_two = ko.observable(data.billing_address_line_two);
    self.billing_details_uuid = ko.observable(data.billing_details_uuid);
    self.billing_region = ko.observable(data.billing_region);
    self.billing_street = ko.observable(data.billing_street);
    self.billing_street_number = ko.observable(data.billing_street_number);
    self.birthday = ko.observable(data.birthday);
    self.city = ko.observable(data.city);
    self.company = ko.observable(data.company);
    self.company_number = ko.observable(data.company_number);
    self.country = ko.observable(data.country);
    self.first_name = ko.observable(data.first_name);
    self.full_pretty_address = ko.observable(data.full_pretty_address);
    self.inserted = ko.observable(data.inserted);
    self.last_name = ko.observable(data.last_name);
    self.legal_form = ko.observable(data.legal_form);

    if (self.legal_form() == undefined) {
        self.legal_form('individual');
    }

    self.mango_pay_user = ko.observable(data.mango_pay_user);
    self.mangopayid = ko.observable(data.mangopayid);
    self.card_id = ko.observable(data.card_id);

    self.nationality = ko.observable(data.nationality);


    self.person_uuid = ko.observable(data.person_uuid);
    self.postal_code = ko.observable(data.postal_code);
    self.tax_id = ko.observable(data.tax_id);
    self.tax_rate = ko.observable(data.tax_rate);
    self.updated = ko.observable(data.updated);
    self.walletmangopayid = ko.observable(data.walletmangopayid);

    self.tax_rate_percent = ko.pureComputed(function () {
        if (self.tax_rate()) {
            var x = ko.utils.arrayFirst(TAXRATE_TYPES, function (item) {
                return item.value == self.tax_rate()
            });
            if (x) {
                return x.percentage
            }
        }
    });

    self.show_custom_tax_rate_form = ko.pureComputed(function(){
       return self.tax_rate() == 'custom';
    });


    self.new_custom_tax_rate_percentage = ko.observable(data.new_custom_tax_rate_percentage);
    self.new_custom_tax_rate_comment = ko.observable(data.new_custom_tax_rate_comment);


    self.pretty_formatted_birthday = ko.computed(function () {
        if (self.birthday()) {
            //return moment(self.birthday()).format('DD.MM.YYYY');
        }
    });

    self.full_name = ko.computed(function () {
        return self.first_name() + ' ' + self.last_name();
    });

    self.pretty_deets = ko.computed(function () {
        var deets = '';

        if (self.company()) {
            deets += self.company() + '<br />'
        }
        deets += self.first_name() + ' ' + self.last_name() + '<br />';
        deets += self.full_pretty_address();

        return deets;
    });

    self.update_self = function (data) {
        self.first_name(data.first_name);
        self.last_name(data.last_name);
        self.full_pretty_address(data.full_pretty_address);

        self.company(data.company);
        self.billing_address(data.billing_address);
        self.billing_address_line_two(data.billing_address_line_two);
        self.billing_details_uuid(data.billing_details_uuid);
        self.billing_region(data.billing_region);
        self.billing_street(data.billing_street);
        self.billing_street_number(data.billing_street_number);
        self.postal_code(data.postal_code);
        self.birthday(data.birthday);
        self.city(data.city);
        self.company(data.company);
        self.company_number(data.company_number);
        self.country(data.country);
        self.full_pretty_address(data.full_pretty_address);
        self.inserted(data.inserted);
        self.legal_form(data.legal_form);
        self.mangopayid(data.mangopayid);

        if (data.nationality) {
            self.nationality(data.nationality);
        }
    }

    // Map our values to google maps values

    self.google_maps_mapping = {
        street_number: self.billing_street_number,
        route: self.billing_street,
        locality: self.city,
        postal_town: self.city,
        sublocality_level_1: self.city,
        administrative_area_level_1: self.billing_region,
        country: self.country,
        postal_code: self.postal_code,
        postal_code_prefix: self.postal_code

    };

    self.google_maps_components = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        postal_town: 'long_name',
        sublocality_level_1: 'long_name',
        administrative_area_level_1: 'long_name',
        country: 'short_name',
        postal_code: 'short_name',
        postal_code_prefix: 'short_name'
    };

    self.reset_location = function () {
        self.billing_address(undefined);
        self.billing_address_line_two(undefined);
        self.billing_details_uuid(undefined);
        self.billing_region(undefined);
        self.billing_street(undefined);
        self.billing_street_number(undefined);
        self.city(undefined);
        self.postal_code(undefined);
        self.country(undefined);
        self.full_pretty_address(undefined);
    };


    self.check_has_error = function () {
        self.checking_error(true);
        if (self.first_name_error() ||
            self.legal_form_error() ||
            self.address_error() ||
            self.shipping_address_error() ||
            self.company_name_error() || self.nationality_error() ||
            self.birthday_error()) {
            return true;
        }
    }
}


export function Country(data) {
    var self = this;
    self.type = "Country"

    self.country_abr = data.country_abr;
    self.country = data.country;
}
