import { Artist } from "../class/Artist";
import { tns } from "tiny-slider/src/tiny-slider";
import {getCookie, setCookie, logPageView, logEvent} from '../common/junkdrawer';

export function GYSBaseViewModel(data) {
  var self = this;
  self.type = "GYSBaseViewModel";

  data.rootvm = this;

  self.rootvm = self;

  self.web_host = data.web_host;
  self.frontend_web_host = data.frontend_web_host;
  self.cache_host = data.cache_host;
  self.image_path_prefix = data.image_path_prefix;
  self.frontend_web_version = ko.observable(
    data.frontend_web_version || "03241999"
  );
  self.key_person_uuid = ko.observable();
  self.is_prod = data.is_prod;

  self.is_busy = ko.observable(false);
  self.initialized = ko.observable(false);

  // Parameter for pages specifically en or de
  self.artists = ko.observableArray([]);
  self.song_examples = ko.observableArray([]);

  /* View Models  -- attach these in document.ready of base  */
  self.ordervm = null;
  self.contactvm = null;
  self.artistsettingsvm = null;

  // Events
  // on state change is custom history.js -- should
  // be compatible with all browsers
  self.scroll_to = function (id) {
    var scrollTo = $(id).offset().top;
    $("html, body")
      .stop()
      .animate(
        {
          scrollTop: scrollTo - 150,
        },
        1000
      );
  };

  self.scroll_to_bottom = function (id) {
    var scrollTo = $(id).height();
    console.log(scrollTo);
    $("html, body").stop().animate(
      {
        scrollTop: scrollTo,
      },
      1000
    );
  };
  self.buildSlider = function (slider_id, artist_list, artist_uuid) {
    // the cloned elements are calling this function with undefinded artist_uuid
    if (typeof artist_uuid === "undefined") {
      return;
    }
    if (
      artist_uuid === artist_list()[artist_list().length - 1].artist_uuid &&
      document.getElementById(slider_id)
    ) {
      tns({
        container: "#" + slider_id,
        nav: false,
        mouseDrag: true,
        touch: true,
        loop: true,
        controlsText: [
          '<i class="bi bi-arrow-left"></i>',
          '<i class="bi bi-arrow-right"></i>',
        ],
        responsive: {
          0: { items: 1, gutter: 12 },
          992: { items: 2, gutter: 20 },
          1200: { items: 3, gutter: 28 },
        },
      });
      for (let ele of document.querySelectorAll("div.tns-slide-cloned")) {
        const artist_uuid = ele
          .querySelector("[data-uuid]")
          ?.getAttribute("data-uuid");
        if (!artist_uuid) {
          break;
        }
        const vm = ko.components.dd["artist-tile"]["viewModel"];
        ko.applyBindings(
          new vm({
            rootvm: self,
            artist: artist_list().find(
              (artist) => artist.artist_uuid === artist_uuid
            ),
          }),
          ele
        );
      }
    }
  };

  self.initialize = function () {
    self.maybe_set_analytics_cookie();



    if (!self.initialized()) {
      window.gys_artists && self.artists(
        window.gys_artists.map((artist) => new Artist(artist)));

      if (window.gys_song_examples)  {
        self.song_examples(window.gys_song_examples.map(
            (artist) => new Artist(artist)));

        const songExampleModals = document.querySelectorAll(
             '[data-song-example-modal]');

        songExampleModals.forEach((modal, index) => {
          modal.addEventListener('hidden.bs.modal', function () {
            self.song_examples()[index].artist_play_click();
          })
        })
      }

      self.set_up_video_event_logging();

      self.maybe_scroll_to_video();

      if(window.location.pathname.indexOf('musiker') >= 0){
          try{
            if(typeof fbq != 'undefined')
            {
                fbq('track', 'ViewContent',
                    {
                     'content_name':'musiker',
                     'content_type':"Page"});
            }

            if (typeof(ga) != 'undefined') {
                ga('send', {
                    hitType: 'page',
                    eventCategory: 'user',
                    eventAction: 'ViewContent',
                });
            }
        }catch(e){
            console.error(e)
        }

      }
    }

    // update our web session
    self.initialized(true);

    // lastly, always add the page view
    logPageView(self.web_host, window.location.pathname,
        window.location.href,
        document.referrer);
  };

  self.maybe_set_analytics_cookie = function () {
    if(getCookie('gysUser') === null){
      setCookie('gysUser', Math.round(Math.random() * 1000000000), 180);
    }
  };

  self.maybe_scroll_to_video = () => {
      console.log('in maybe scroll video');
      if(window.location.href.indexOf('#video') > 0){
        setTimeout(() => {
            self.scroll_to('#video');
        }, 50);
      }

      if(window.location.href.indexOf('#schlaflied') > 0){
        setTimeout(() => {
            self.scroll_to('#schlaflied');
        }, 50);
      }

      if(window.location.href.indexOf('#reaction') > 0){
        setTimeout(() => {
            self.scroll_to('#reaction');
        }, 150);
      }

      if(window.location.href.indexOf('#ueberraschung-mama') > 0){
        setTimeout(() => {
            self.scroll_to('#ueberraschung-mama');
        }, 150);
      }
  };

  self.set_up_video_event_logging = () =>{
    // Log when videos get played
    let video_list =  document.getElementsByTagName('video');
    console.log(video_list);
    ko.utils.arrayForEach(video_list, (video_player) => {
        video_player.addEventListener('play', (e) => {
           logEvent(window.sc_web_host, 'video_play',
                window.location.pathname, video_player.id);

              try{
                  if(typeof fbq != 'undefined')
                  {
                    fbq('track', 'VideoPlay',
                        {'value': 0,
                         'content_name':video_player.id,
                         'content_ids': [video_player.id],
                         'content_type':"Artist"});
                  }

                  if (typeof(ga) != 'undefined') {
                    ga('send', {
                        hitType: 'event',
                        eventCategory: 'engagement',
                        eventAction: 'video_play',
                        eventLabel: video_player.id
                    });
                  }
              }catch(e){
                    console.error(e)
              }



        });

    });
  }

  self.email = ko.observable();
  self.accept_terms = ko.observable(false);

  self.show_errors = ko.observable(false);
  self.show_already_registered = ko.observable(false);
  self.show_success = ko.observable(false);

  self.has_errors = ko.pureComputed(() => {
    return !self.email() || !self.accept_terms();
  });
  self.form_submitted = ko.observable(false);

  self.submitForm = function () {
    // form complete?
    self.show_errors(false);
    self.show_success(false);
    self.show_already_registered(false);
    if (self.has_errors() === true) {
      self.show_errors(true);
      return false;
    }
    // send form
    self.is_busy(true);
    return $.ajax({
      url: self.web_host + "/newsletter/subscribe",
      type: "POST",
      processData: false,
      data: ko.toJSON({
        email: self.email(),
      }),

      contentType: "application/json; charset=utf-8",

      success: function (data) {
        if (data.success === true) {
          self.show_success(true);
        } else {
          self.show_already_registered(true);
        }
      },
      error: function (data) {
        console.log(data);
      },
      complete: function () {
        self.form_submitted(true);
        self.email(undefined);
        self.accept_terms(false);
        self.is_busy(false);
      },
    });
  };
}
