import {getCookie, setCookie, logEvent} from '../common/junkdrawer';

export function Song(data){
    var self = this;
    self.type = "Song";

    self.rootvm = data.rootvm;
    self.song_uuid = ko.observable(data.song_uuid);
    self.song_type =  data.song_type;

    self.original_path = data.original_path;
}


export function Order(data) {

    var self = this;

    self.type = "Order";

    self.rootvm = data.rootvm;
    self.gys_order_uuid = ko.observable(data.gys_order_uuid);

    self.email_address = ko.observable(data.email_address);
    self.due_date = ko.observable(data.due_date);

    self.status = ko.observable(data.status);


    self.artist = data.artist;
    self.phone = data.phone;

    self.delivered_song = ko.observable();
    self.delivered_greeting = ko.observable();

    if (data.delivered_song){
        self.delivered_song(new Song(data.delivered_song));
    }
    if (data.delivered_greeting){
        self.delivered_greeting(new Song(data.delivered_greeting));
    }

    self.user_feedback = ko.observable(data.user_feedback);
    self.user_rating = ko.observable(data.user_rating);

    self.artist_payment = ko.observable(data.artist_payment);


    // Specific Questions
    // Page 1 - Who For
    self.who_is_it_for_name = ko.observable(data.receiver_name);
    self.who_is_it_from_name = ko.observable(data.sender_name);
    self.song_story = ko.observable(data.song_story);
    self.song_important = ko.observable(data.song_important);
    self.anlass = ko.observable(data.anlass);


    self.pretty_anlass = ko.pureComputed(function(){
        if(self.anlass() == 'birthday'){
            return 'Geburtstag';
        }
        else if (self.anlass() == 'anniversary'){
            return 'Jahrestag';
        }
        else if (self.anlass() == 'proposal'){
            return 'Heiratsantrag';
        }
        else if (self.anlass() == 'funeral'){
            return 'Trauerfeier';
        }
        else if (self.anlass() == 'other'){
            return 'Sonstiger Anlass';
        }
    });

    // Page 2
    self.genre = ko.observable(data.genre);
    self.song_language = ko.observable(data.song_language);
    self.stimmung = ko.observable(data.stimmung);
    self.selected_artist = ko.observable();

    self.pretty_genre = ko.pureComputed(function(){
        if(self.genre() == 'ballad'){
            return 'Ballade';
        }
        else if (self.genre() == 'acoustic-pop'){
            return 'Acoustic Pop';
        }
        else if (self.genre() == 'acoustic-folk'){
            return 'Acoustic Folk';
        }
        else if (self.genre() == 'pop'){
            return 'Pop';
        }
    });

    //Page 3
    self.extra_info = ko.observable(data.extra_info);
    self.email = ko.observable(data.email_address);
    self.first_name = ko.observable(data.first_name);

    // Page 4

    self.giftcode = ko.observable();
    self.gift_card_uuid = ko.observable();
    self.gift_amount = ko.observable();
    self.gift_type = ko.observable();

    // Page 5
    self.confirm_email = ko.observable();


    self.save_to_cookie = page_num => {
        // Save this order as a cookie
        console.log("Saving order progress as a cookie");

        var page = {};

        if(page_num == 1){
            page = ko.toJSON({who_for: self.who_is_it_for_name(),
                               who_from: self.who_is_it_from_name(),
                               story: self.song_story(),
                               important: self.song_important(),
                               anlass: self.anlass()});

        }
        if(page_num == 2){
            page = ko.toJSON({genre: self.genre(), lang: self.song_language(),
            stimmung: self.stimmung(), artist: self.selected_artist()});
        }

        if(page_num == 3){
            page = ko.toJSON({email: self.email(),
                first_name: self.first_name(),
                extra_info: self.extra_info(),
                artist: self.selected_artist()});
        }

        if(page_num == 4){
            page = ko.toJSON({giftcode: self.giftcode(),
                gift_card_uuid : self.gift_card_uuid(),
                gift_type : self.gift_type(),
                gift_amount : self.gift_amount()});
        }

        setCookie('GYS_p' + page_num, JSON.stringify(page));

        // also log that we saved / completed
        logEvent(self.rootvm.web_host, 'order_page_' + page_num,
            window.location.href, page);
    }

    self.load_from_cookie = () => {
        // PAGE 1
        let page1 = JSON.parse(getCookie('GYS_p1'));
        if(page1){
            let parsed = JSON.parse(page1);
            self.who_is_it_for_name(parsed.who_for);
            self.who_is_it_from_name(parsed.who_from);
            self.song_story(parsed.story);
            self.song_important(parsed.important);
            self.anlass(parsed.anlass);
        }

        //PAGE 2
        let page2 = JSON.parse(getCookie('GYS_p2'));
        if(page2){
            let parsed = JSON.parse(page2);
            self.genre(parsed.genre);
            self.song_language(parsed.lang);
            self.selected_artist(parsed.artist);
            self.stimmung(parsed.stimmung);
        }

        let page3 = JSON.parse(getCookie('GYS_p3'));
        if(page3){
            let parsed = JSON.parse(page3);
            self.extra_info(parsed.extra_info);
            self.email(parsed.email);
            self.first_name(parsed.first_name);
        }

        let page4 = JSON.parse(getCookie('GYS_p4'));
        if(page4){
            let parsed = JSON.parse(page4);
            self.gift_card_uuid(parsed.gift_card_uuid);
            self.gift_amount(parsed.gift_amount);
            self.gift_type(parsed.gift_type);
            self.giftcode(parsed.giftcode);
        }
    }

    self.load_from_global = () => {
        if(window.GYSOrderInProgress === undefined){
            console.error("No Global Order!")
            return false;
        }


        // This assumes the existence of window.GYSOrderInProgress
        // PAGE 1
        let page1 = window.GYSOrderInProgress.page_1
        if(page1){
            self.who_is_it_for_name(page1.who_for);
            self.who_is_it_from_name(page1.who_from);
            self.song_story(page1.story);
            self.song_important(page1.important);
            self.anlass(page1.anlass);
        }

        //PAGE 2
        let page2 = window.GYSOrderInProgress.page_2
        if(page2){
            self.genre(page2.genre);
            self.song_language(page2.lang);
            self.selected_artist(page2.artist);
            self.stimmung(page2.stimmung);
        }

        let page3 = window.GYSOrderInProgress.page_3
        if(page3){
            self.extra_info(page3.extra_info);
            self.first_name(page3.first_name);
            self.email(page3.email);
        }
    }

    self.order_data_as_dict = () => {
        var page = {};

        page["page_1"] = {who_for: self.who_is_it_for_name(),
                           who_from: self.who_is_it_from_name(),
                           story: self.song_story(),
                           important: self.song_important(),
                           anlass: self.anlass()};

        page["page_2"] = {genre: self.genre(), lang: self.song_language(),
            stimmung: self.stimmung(), artist: self.selected_artist()};

        page["page_3"] = {extra_info: self.extra_info(),
                first_name:self.first_name(),
                email: self.email() };
        console.log(page);

        return page;
    }


    self.reset = () => {
        // clear all fields
        self.who_is_it_for_name(undefined);
        self.who_is_it_from_name(undefined);
        self.song_story(undefined);
        self.song_important(undefined);
        self.anlass(undefined);
        self.genre(undefined);
        self.song_language(undefined);
        self.stimmung(undefined);
        self.extra_info(undefined);
		self.selected_artist(undefined);

		// delete cookies
        setCookie('GYS_p1', JSON.stringify(ko.toJSON({})));
        setCookie('GYS_p2', JSON.stringify(ko.toJSON({})));
        setCookie('GYS_p3', JSON.stringify(ko.toJSON({})));
    }
}

