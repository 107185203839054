import {v4 as uuidv4} from 'uuid';

ko.components.register('form-radio', {
	viewModel: function (params) {
		var self = this;
		self.type = "FormRadioComponentViewModel"
		self.id = params.id || 'radio-' + uuidv4();
		self.rootvm = params.rootvm;

		self.ko_observable = ko.observable(params.ko_observable);
		self.options = params.options ||  ko.observableArray([{value: 'dummy', i18n: 'dummy'}]);
		self.optionsText = params.optionsText || function(item) {return self.getOptionsText(item)},
		self.optionsValue = params.optionsValue || 'value',
		self.name = params.name;
		self.hint = params.hint || '';
		self.optionsShowPopover = params.optionsShowPopover || false;
		self.optionsI18nLabelSuffix = params.optionsI18nLabelSuffix || undefined,
		self.optionsI18nHelpSuffix = params.optionsI18nHelpSuffix || undefined,
		self.popoverToggle = params.popoverToggle || false;
		self.popoverTitle = params.popoverTitle || '';
		self.popoverText = params.popoverText || '';
		self.is_required = params.is_required;
		self.is_disabled = ko.observable(params.is_disabled);
		self.is_valid = ko.observable(params.is_valid || false);
		self.is_invalid = ko.observable(params.is_invalid || false);

		self.getOptionsText = function(item) {
			return typeof(item.i18n) === 'object' ?
				i18nextko.i18n.translator.translate(item.i18n.key, item.i18n.options) :
				i18nextko.i18n.translator.translate(item.i18n);
		}
	},
	template: { fromUrl: 'templates/form-radio.html', maxCacheAge: 1234 }
});
