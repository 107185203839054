import {v4 as uuidv4} from 'uuid';

ko.components.register('form-input', {
	viewModel: {
        /* an example of how we can get at the element / component info
         * if we need to */
	    createViewModel: function (params, componentInfo) {
			var vm = function(params){
				var self = this;
				self.type = "FormInputComponentViewModel"
				self.id = params.id || 'input-' + uuidv4();
				self.rootvm = params.rootvm;
				self.container_class = params.container_class || 'form-group';

				self.input_type = params.input_type || params.inputs && params.inputs[0].input_type || 'text';
				self.ko_observable = ko.observable(params.ko_observable || params.inputs && params.inputs[0].ko_observable);
				self.name = params.name;
				self.hint = params.hint || '';

                if(typeof params.hint_placement == "function"){
                    self.hint_placement = params.hint_placement();
                }
                else{
                    // either top or bottom
                    self.hint_placement = params.hint_placement || 'top';                           }
				self.popoverToggle = params.popoverToggle || false;
				self.popoverTitle = params.popoverTitle || '';
				self.popoverText = params.popoverText || '';
				self.placeholder = params.placeholder || params.inputs && params.inputs[0].placeholder || '';
				self.is_required = params.is_required;
				self.is_disabled = ko.observable(params.is_disabled);
				self.is_valid = ko.observable(params.is_valid || false);
				self.valid_feedback = ko.observable(params.valid_feedback || '');
				self.is_invalid = ko.observable(params.is_invalid || false);
				self.invalid_feedback = ko.observable(params.invalid_feedback || '');
				self.min = params.min || undefined;
				self.max = params.max || undefined;
				self.step = params.step || undefined;
				self.event = params.event || undefined;
				self.executeOnEnter = params.executeOnEnter || undefined;

				self.is_input_group = params.is_input_group || false;
				self.inputs = ko.observableArray(params.inputs || [{
					input_type: self.input_type,
					ko_observable: self.ko_observable,
					placeholder: self.placeholder,
				}]);
				self.input_group = params.input_group || 'input-group';

                // These are for buttons
				self.input_group_append = ko.observableArray(params.input_group_append || []);
				self.input_group_prepend = ko.observableArray(params.input_group_prepend || []);

                self.input_append_percent = ko.observable(params.input_append_percent || false);


				// Use this callback to do allow a call back binding or
				// do any other post processing.
				// Most useful for google search hookup

				// should use binding like this:
				// data-bind="afterRenderComponentInput: afterRenderInputCallback"
				self.afterRenderInputCallback = function(element){
				    // if params is passed in -- then do the params callback
					if(params.afterRenderInput){
					   params.afterRenderInput(element);
					}
				}
			};
			return new vm(params);
	    }
	},
	template: `
            <div data-bind="class: container_class, css: {'has-error': is_invalid()}">
                <!--ko if: name -->
                    <label class="sc-form-label" data-bind="attr: {for: id}">
                        <span data-bind="i18n: name"></span>
                        <span data-bind="if: is_required">*</span>
                    </label>
                    <!--ko if: popoverToggle -->
                    <a class="mdi mdi-help-circle" data-toggle="popover" tabindex="0" data-html="true" data-trigger="focus"
                        data-bind="
                            popover:function() { return i18nextko.i18n.translator.translate(popoverText)},
                            popoverTitle:function() { return i18nextko.i18n.translator.translate(popoverTitle)}
                    "></a>
                    <!-- /ko -->
                    <!-- ko if: hint_placement == 'top' && hint -->
                    <small class="form-text text-muted sc-form-hint" data-bind="attr: {id: id+'-hint'}, i18n: hint"></small>
                    <!-- /ko -->
                <!-- /ko -->
                <!-- ko if: input_type !== 'range' -->
                <div data-bind="css: is_input_group ? input_group : ''">
                    <!-- ko foreach: input_group_prepend -->
                    <div class="input-group-prepend">
                        <button class="btn btn-primary mdi sc-icon-only" type="button"
                            data-bind="class: $data.icon, click: $data.action"
                        ></button>
                    </div>
                    <!-- /ko -->
                    <!-- ko foreach: inputs -->
                    <input class="form-control" data-bind="
                        textInput: $data.ko_observable(),
                        i18n: {placeholder: $data.placeholder},
                        attr: {
                            id: $data.id !== undefined ? $data.id :  $parent.id,
                            type: $data.input_type,
                            'aria-describedby': $parent.id+'-hint',
                            required: $parent.is_required,
                            min: $parent.min,
                            max: $parent.max,
                            step: $parent.step
                        },
                        disable: $parent.is_disabled(),
                        css: {'is-valid': $parent.is_valid(), 'is-invalid': $parent.is_invalid()},
                        event: $parent.event,
                        executeOnEnter: {callback: $parent.executeOnEnter},
                        afterRenderComponentInput: $parent.afterRenderInputCallback
                    ">
                    <!-- /ko -->
                    <!-- ko foreach: input_group_append -->
                    <div class="input-group-append">
                        <button class="btn btn-primary mdi sc-icon-only" type="button"
                            data-bind="class: $data.icon, click: $data.action"
                        ></button>
                    </div>
                    <!-- /ko -->

                    <!-- ko if: input_append_percent -->
                    <div class="input-group-append">
                       <span class="input-group-text">&#37;</span>
                    </div>
                    <!-- /ko -->
                    <span class="invalid-feedback sc-invalid-feedback" data-bind="i18n: invalid_feedback"></span>
                    <span class="valid-feedback sc-valid-feedback" data-bind="i18n: valid_feedback"></span>
                    <!-- ko if: hint_placement == 'bottom' && hint -->
                    <small class="form-text text-muted sc-form-hint" data-bind="attr: {id: id+'-hint'}, i18n: hint"></small>
                    <!-- /ko -->

                </div>
                <!-- /ko -->
                <!-- ko if: input_type === 'range' -->
                <div class="form-row">
                    <div class="col-4">
                        <input type="number" class="form-control" data-bind="
                            textInput: ko_observable(),
                            disable: is_disabled(),
                            css: {'is-valid': is_valid(), 'is-invalid': is_invalid()}
                        ">
                    </div>
                    <div class="col-8">
                        <input class="custom-range" type="range" style="padding-top: 12px;" data-bind="
                            textInput: ko_observable(),
                            attr: {
                                id: id,
                                'aria-describedby': id+'-hint',
                                required: is_required,
                                min: min,
                                max: max,
                                step: step
                            },
                            disable: is_disabled(),
                            css: {'is-valid': is_valid(), 'is-invalid': is_invalid()},
                            event: event,
                            executeOnEnter: executeOnEnter
                        ">
                    </div>
                </div>
                <span class="invalid-feedback sc-invalid-feedback" data-bind="i18n: invalid_feedback"></span>
                <span class="valid-feedback sc-valid-feedback" data-bind="i18n: valid_feedback"></span>
                <!-- /ko -->
            </div>

    `
});
